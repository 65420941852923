export interface Mp4Config {}

export const Mp4: React.FC<Mp4Config> = ({}) => {
  return(
    <svg id="Camada_2" data-name="Camada 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .mp4-1 {
              fill: #fff;
            }

            .mp4-2 {
              fill: #b3b3b3;
            }

            .mp4-2, .mp4-3 {
              fill-rule: evenodd;
            }

            .mp4-3 {
              fill: #d6d6d6;
            }
          `}
        </style>
      </defs>
      <g id="Camada_1-2" data-name="Camada 1">
        <g>
          <g id="Grupo_736" data-name="Grupo 736">
            <path id="Caminho_136" data-name="Caminho 136" className="mp4-2" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11c-2.26,0-4.11-1.84-4.11-4.1h0V4.1C0,1.83,1.85,0,4.11,0h21.94s13.71,13.67,13.71,13.67Z"/>
            <path id="Caminho_138" data-name="Caminho 138" className="mp4-3" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7s12.9,12.9,12.9,12.9Z"/>
          </g>
          <g>
            <path className="mp4-1" d="m17.03,34.29h-1.36v-3.27c0-.63-.09-1.08-.27-1.37s-.48-.42-.9-.42c-.36,0-.66.18-.91.53s-.37.78-.37,1.28v3.25h-1.37v-3.38c0-1.12-.39-1.68-1.18-1.68-.37,0-.67.17-.91.5s-.35.77-.35,1.31v3.25h-1.36v-6h1.36v.95h.02c.43-.73,1.06-1.09,1.89-1.09.41,0,.78.11,1.09.34s.52.53.64.9c.45-.83,1.11-1.24,1.99-1.24,1.32,0,1.98.81,1.98,2.44v3.7Z"/>
            <path className="mp4-1" d="m20.07,33.41h-.02v3.64h-1.36v-8.76h1.36v1.05h.02c.46-.8,1.14-1.2,2.04-1.2.76,0,1.36.27,1.78.8s.64,1.25.64,2.16c0,1-.24,1.8-.72,2.4s-1.14.9-1.97.9c-.77,0-1.36-.34-1.77-1.01Zm-.04-2.37v.74c0,.44.14.81.42,1.11s.63.46,1.06.46c.5,0,.9-.2,1.19-.59s.43-.94.43-1.64c0-.59-.13-1.05-.4-1.39s-.63-.5-1.09-.5c-.48,0-.87.17-1.17.52s-.44.77-.44,1.29Z"/>
            <path className="mp4-1" d="m30.46,25.89v5.43h1.25v1.13h-1.25v1.85h-1.32v-1.85h-3.8v-1.14c.34-.39.7-.81,1.06-1.26s.71-.91,1.04-1.38.64-.94.93-1.42.53-.93.72-1.36h1.37Zm-1.32,5.43v-3.53c-.43.77-.85,1.43-1.26,2s-.81,1.08-1.17,1.52h2.43Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
