export interface SvgConfig {}

export const Svg: React.FC<SvgConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .svg-1 {
              fill: #fff;
            }

            .svg-2 {
              fill: none;
              isolation: isolate;
            }

            .svg-3 {
              fill: #b3b3b3;
            }

            .svg-3, .svg-4 {
              fill-rule: evenodd;
            }

            .svg-4 {
              fill: #d6d6d6;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_754" data-name="Grupo 754">
        <g id="Grupo_739" data-name="Grupo 739">
          <path id="Caminho_136" data-name="Caminho 136" className="svg-3" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_138" data-name="Caminho 138" className="svg-4" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="svg" className="svg-2">
          <g className="svg-2">
            <path className="svg-1" d="m9.59,34.75v-1.47c.59.45,1.25.68,1.96.68.96,0,1.44-.28,1.44-.85,0-.16-.04-.29-.12-.41-.08-.11-.19-.21-.33-.3s-.3-.16-.49-.23-.4-.15-.63-.23c-.29-.11-.55-.23-.78-.36s-.42-.27-.57-.43c-.15-.16-.27-.35-.35-.55s-.12-.44-.12-.72c0-.34.08-.63.24-.89.16-.26.37-.47.64-.65.27-.17.57-.31.92-.4.34-.09.69-.13,1.06-.13.65,0,1.23.1,1.74.29v1.39c-.49-.34-1.06-.51-1.7-.51-.2,0-.38.02-.54.06s-.3.1-.41.17-.2.16-.27.26c-.06.1-.1.21-.1.34,0,.15.03.28.1.38s.16.2.28.28c.13.08.28.15.45.22.18.07.38.14.6.21.3.12.57.25.81.38s.44.27.61.43c.17.16.3.35.39.56.09.21.14.46.14.75,0,.36-.08.67-.25.93s-.38.48-.66.66-.59.3-.95.39c-.36.08-.73.13-1.13.13-.77,0-1.43-.13-1.99-.38Z"/>
            <path className="svg-1" d="m22.1,27.97l-2.71,7h-1.72l-2.58-7h1.72l1.55,4.89c.12.36.19.67.22.94h.02c.04-.34.1-.64.19-.92l1.63-4.92h1.67Z"/>
            <path className="svg-1" d="m29.43,34.41c0,2.57-1.29,3.86-3.88,3.86-.91,0-1.71-.15-2.39-.46v-1.45c.77.44,1.49.66,2.18.66,1.66,0,2.5-.82,2.5-2.45v-.77h-.03c-.52.89-1.31,1.34-2.37,1.34-.85,0-1.54-.31-2.06-.93-.52-.62-.78-1.46-.78-2.5,0-1.19.28-2.13.84-2.84s1.33-1.05,2.31-1.05c.93,0,1.61.38,2.06,1.13h.03v-.97h1.59v6.44Zm-1.57-2.65v-.91c0-.49-.16-.91-.49-1.26-.33-.35-.73-.52-1.22-.52-.6,0-1.07.22-1.41.67-.34.44-.51,1.06-.51,1.86,0,.69.16,1.24.49,1.65.33.41.76.62,1.3.62s.99-.2,1.33-.59c.34-.39.51-.9.51-1.51Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
