import { Box, Typography } from "@mui/material";
import { BaseSvg } from "../../../../../../../../../components/BaseSVg/BaseSvg";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ClockField } from "../../../../../../../../WorkSpaceItem/WorkSpaceItemListFileCompose/ClockField";
import { ItemValidationRule } from "../../../../../../../../../model/ItemModel";
import LoaderComp from "../../../../../../../../../components/Loader/LoaderComp";

interface IDocumentDetailInternalDate
{
    textInformation: string;
    isReleaseConst: boolean;
    timeDeadLine: string;
    validationRule: ItemValidationRule;
    timeDeadLineInternal?: string;
    actionForwadOrBackWait: boolean;
}

export default function DocumentDetailInternalDate({
    textInformation,
    isReleaseConst,
    timeDeadLine,
    validationRule,
}: IDocumentDetailInternalDate)
{
    const { t } = useTranslation();

    return (
        <Box sx={{
            display: 'flex',
            gap: 1
        }}>

            <ClockField 
                isReleaseConst={isReleaseConst}
                timeDeadLine={timeDeadLine}
                validatioRule={validationRule}
                itemTemporality={undefined}
            />
            <Typography
                sx={{
                    textAlign: "left",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    display: 'flex',
                    gap: 1,
                    whiteSpace:"nowrap"
                }}
            >
                {textInformation}: { timeDeadLine !== undefined ? (
                    <Typography>
                        { moment(timeDeadLine).format("DD/MM/YYYY") }
                    </Typography>
                ) : (
                    <Typography>
                        {t("DetailDocumentPage.NoTerm")}
                    </Typography>
                ) }
            </Typography>

        </Box>
    )
}