import React, {useState,useEffect} from "react";
import { initializeIcons, MessageBarType, TextField } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ResetPassword } from "../../../services/UserServices";
import { PasswordReset } from "../../../model/UserModel";
import forgetPasswordIcon from '../../../assets/icons/esqueceu-sua-senha.svg'
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormErrors from "../../PublicLayout/components/Handle/Form/Root/components/FormErrors/FormErrors";
import FormButton from "../../PublicLayout/components/Handle/Form/Root/components/FormButton/FormButton";
import {LeftForm} from "../../PublicLayout/components/Handle/LeftForm";
import BtnBigWithIcon,{ ButtonTyppe} from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import ReCAPTCHA from "react-google-recaptcha";

initializeIcons();

const ResetPasswordComp = (props: any) => {
    const key = require("../../../services/key.json");
    
    const [isChecked, setCheckedState] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const [load, setLoad] = useState<boolean>(false);
    const [lg, setLanguage] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const navigate = useNavigate();

    const [messagePass, setMessagePass] = useState<string>("");
    const [typeMessagePass, setTypeMessagePass] = useState<MessageBarType>(MessageBarType.error);

    const recaptchaRef = React.useRef(null);

    const regex = new RegExp("([!#-'+/-9=?A-Z^-~-]+(.[!#-'+/-9=?A-Z^-~-]+)|'([]!#-[^-~ \t]|(\[\t -~]))+')@([!#-'+/-9=?A-Z^-~-]+(.[!#-'+/-9=?A-Z^-~-]+)|[[\t -Z^-~]*])");

    const onChangeEmail =
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined ) {
            setEmail(newValue);
        }
        else
        {
            setEmail("");
        }
    };

    const handleSubmit = (event: any) => {
        if(!regex.test(email)){
            setTypeMessagePass(MessageBarType.error);
            setMessagePass(t("InvalidEmail.message"));
            setCheckedState(true);
            return
        };

        if(!captchaToken){
            setTypeMessagePass(MessageBarType.error);
            setMessagePass(t("InvalidCaptcha.message"));
            setCheckedState(true);
            return
        };

        let reset: PasswordReset = {
            login: email,
            captchaToken: captchaToken
        };
        
        setLoad(true)
        ResetPassword(reset)
        .then(()=>{    
            navigate('/emailSendSuccess');
        })
        .catch((error)=>{
            setTypeMessagePass(MessageBarType.error)
            setMessagePass(error.response.data);
            setCheckedState(true);
        })
        .finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        var lgn = localStorage.getItem("i18nextLng");
        if(lgn == "pt-BR"){
            setLanguage(true);
        }
        else{
            setLanguage(false);
        }

    })

    const login = (event: any) => {
        navigate("/login");
    };
    
    return (
        <LeftForm
            formRootInstance={{
                title:t("Login.forgotYourPass"),
                subTitle:t("Login.fillTheEmailRegistered"),
                iconToRender:forgetPasswordIcon
            }}
        >
            <Grid container sx={{
                gap: 4
            }}>
                <Grid item xs={12} sx={{
                        marginTop: '32px'
                    }}>
                    <Typography
                        component='label'
                        sx={{
                            textAlign: "left",
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 1,
                            marginBottom: '10px'
                        }}
                        >
                        E-mail
                    </Typography>
                    <TextField 
                        onChange={onChangeEmail}
                        value={email}
                        styles={{
                            fieldGroup: {
                            background: "#FBFBFB",
                            border: "2px solid #2D2D2D4D",
                            borderRadius: "8px",
                            opacity: 1,
                            height: '54px'
                            }
                        }}
                        placeholder={t("Login.typeYourEmail")}
                        onFocus={() => {
                            setCheckedState(false);
                        }}
                    />
                </Grid>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={key.siteKey}
                    onChange={(token) => setCaptchaToken(token)}
                />
                <FormErrors 
                    messagePass={messagePass}
                    isChecked={isChecked}
                />
                <FormButton 
                    load={load}
                    btnBigWithIconConfig={{
                        text:t("Enviar.message"),
                        onClick:handleSubmit
                    }}
                />
                <BtnBigWithIcon 
                    isPrimary={ButtonTyppe.secondary}
                    text={t("IrParaLogin.message")}
                    onClick={login}
                
                />
            </Grid>
        </LeftForm>
    );
}

export default ResetPasswordComp;