import React, { useEffect, useReducer, useState } from "react";
import { MessageBarType, TextField } from "@fluentui/react";
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
GetValidateCode,PutNewPassword
} from "../../../services/IdentitiServices";
import { PutPassword } from "../../../model/IdentitiModel";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from '@mui/material'
import { RealTimePasswordCheck } from "./components/RealTimePasswordCheck/RealTimePasswordCheck";
import { reducer } from "./components/RealTimePasswordCheck/Reducer/PasswordReducer";
import { initialState } from "./components/RealTimePasswordCheck/InitialState/initialState";
import {LeftForm} from "../../PublicLayout/components/Handle/LeftForm";
import AlertComponent from "../../../components/Alert/AlertComponent";
import FormButton from "../../PublicLayout/components/Handle/Form/Root/components/FormButton/FormButton";
import BtnBigWithIcon,{ ButtonTyppe} from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";

const NewUserPassword = (props: any) => {

    const [searchParams] = useSearchParams();
    const [isChecked, setCheckedState] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [load, setLoad] = useState<boolean>(false);

    const [messagePass, setMessagePass] = useState<string>("");
    const [typeMessagePass, setTypeMessagePass] = useState<MessageBarType>(MessageBarType.error);

    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(true);
    const codPassword = searchParams.get('code');

    const [hidenPassword, setHidenPassword] = useState<boolean>(false);
    const [hidenGoLOgin, setHidenGoLOgin] = useState<boolean>(true);
    const { t, i18n } = useTranslation();
    const navegate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initialState);

    const onChangePassword =
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined ) {
            setPassword(newValue);
        }
    }; 

    const onChangePasswordConfirm =
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue != undefined ) {
            setPasswordConfirm(newValue);
        }
    };

    function verifyPasswordConditions(): boolean {
        if (state.capitalCharacter.error ||
            state.minimunCharacters.error ||
            state.numberCharacter.error ||
            state.specialCharacter.error ) {
                return false;
        }
        return true;
    }

    const sendNewPassWord = () => {
        setCheckedState(false);
        setLoad(true);
        if(codPassword != null)
        {
            let bcrypt = require('bcryptjs');
            let salt = bcrypt.genSaltSync(10);

            if(password == passwordConfirm)
            {
                let hashPassword = bcrypt.hashSync(password, salt);
                let putPassword:PutPassword={
                    code:codPassword,
                    newPassword:hashPassword
                };
                
                if (!verifyPasswordConditions()) {
                    setCheckedState(true);
                    setMessagePass(t("AlgoErradoAconteceu.message"));
                    setLoad(false);
                    return 
                }

                PutNewPassword(putPassword)
                .then(()=>{
                    setTypeMessagePass(MessageBarType.success)
                    setIsSuccess(true);
                    setCheckedState(true);
                    setMessagePass(t("AlteradoComSucesso.message"));
                    setDisable(true);
                    
                    setHidenPassword(true);
                    setHidenGoLOgin(false);

                    navegate('/login');
                })
                .catch(()=>{
                    setCheckedState(true);
                    setMessagePass(t("AlgoErradoAconteceu.message"));
                })
                .finally(() => {
                    setLoad(false);
                })
            }
            else
            {
                setCheckedState(true);
                setLoad(false);
                setMessagePass(t("SenhasNaoBatem.message"));
            }
        }
    };

    const navegateToLogin = () => {
        navegate("/login");
    }
    
    useEffect(() => {
        if(codPassword != null)
        {
            GetValidateCode(codPassword).then(()=>{
                setDisable(false);
            }).catch(()=>{
                setCheckedState(true);
                setMessagePass(t("CodigoNaoEncontrado.message"));
                setHidenPassword(true);
                setHidenGoLOgin(false);
            });
        }
        else
        {
            setCheckedState(true);
            setMessagePass(t("SemCodigo.message"));
            setHidenPassword(true);
            setHidenGoLOgin(false);
        }
    }, []);

    const login = (event: any) => {
        navegate("/login");
      };

    return (
        <LeftForm
            formRootInstance={{
                title:t("Login.putYourNewPass"),
                subTitle:t("Login.fillTheFieldsDown")
            }}
        >
        <Grid container sx={{
            gap: 2
        }}>
            <Grid item xs={12} sx={{
                    marginTop: '32px'
                }}>
                    <Typography
                        component='label'
                        sx={{
                            textAlign: "left",
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 1,
                            marginBottom: '10px'
                        }}
                        >
                        {t("Senha.message")}
                    </Typography>
                    <TextField 
                        onChange={onChangePassword}
                        value={password}
                        styles={{
                            fieldGroup: {
                            background: "#FBFBFB",
                            border: "2px solid #2D2D2D4D",
                            borderRadius: "8px",
                            opacity: 1,
                            height: '54px'
                            }
                        }}
                        type='password'
                        disabled={disable}
                        onFocus={() => {
                            setCheckedState(false);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sx={{
                    marginTop: '32px'
                }}>
                    <Typography
                        component='label'
                        sx={{
                            textAlign: "left",
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 1,
                            marginBottom: '10px'
                        }}
                        >
                        {t("ConfirmarSenha.message")}
                    </Typography>
                    <TextField 
                        onChange={onChangePasswordConfirm}
                        value={passwordConfirm}
                        styles={{
                            fieldGroup: {
                            background: "#FBFBFB",
                            border: "2px solid #2D2D2D4D",
                            borderRadius: "8px",
                            opacity: 1,
                            height: '54px'
                            }
                        }}
                        type='password'
                        disabled={disable}
                        onFocus={() => {
                            setCheckedState(false);
                        }}
                    />
                </Grid>
                { isChecked ? (
                    <Grid item xs={12} sx={{
                        marginBottom: '10px'
                    }}>
                        <AlertComponent 
                            text={messagePass}
                            type={isSuccess ? "success" : "error"}
                        />
                    </Grid>
                ) : (
                    <RealTimePasswordCheck 
                        password={password}
                        passwordConfirm={passwordConfirm}
                        state={state}
                        dispatch={dispatch}
                    />
                ) }
            </Grid>
            <FormButton 
                load={load}
                btnBigWithIconConfig={{
                    text:t("Enviar.message"),
                    onClick:sendNewPassWord
                }}
            />
            <BtnBigWithIcon 
                isPrimary={ButtonTyppe.secondary}
                text={t("IrParaLogin.message")}
                onClick={login}
            
            />
        </LeftForm>
    );
}

export default NewUserPassword;