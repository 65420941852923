import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useProfile } from "../../context/ProfileProvider/useProfile";
import { IUser } from "../../context/AuthProvider/types";

import { Api } from "../../services/api";
import { AxiosRequestConfig, AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { RedirectNavigate } from "./RedirectNavigate";
import WaitPage from "../ErroPage/WaitPage"
import loginBackground from '../../assets/images/login_background.jpeg'
import { BackgroundImage } from "./components/BackgroundImage/BackgroundImage";
import { useTranslation } from "react-i18next";

const ILayoutPublic: React.FunctionComponent<IUser> = (props) => {
  const auth = useAuth();
  const profile = useProfile();
  const navegate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const { t } = useTranslation();

  Api.interceptors.response.use(
    function (config: AxiosRequestConfig) {
      return config;
    },
    function (error: AxiosError) {
      return Promise.reject(error);
    }
  );
  
  const getUserActualLocation = useMemo(() => {
    if (location.pathname === '/login') {
      setIsLogin(true)
      return
    }
    setIsLogin(false)
  }, [location])

  useEffect(() => {
    if (auth.token && profile.login == undefined) {
      profile.create(auth.token).then((error) => {
        RedirectNavigate(navegate)
      });
    }
    else if (auth.token && profile.login) {
      RedirectNavigate(navegate)
    }
  }, [auth.token, profile.login]);

  return (
    <BackgroundImage 
      component={'img'}
      backgroundImage={loginBackground}
    >
          <Outlet />
    </BackgroundImage>
  );
};

export default ILayoutPublic;
