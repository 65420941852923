import { ApiAuth } from "../../services/api";
import { IUser } from "./types";

const key :string = "w3kSistem"

export function SetUserLocalStorage(user: IUser | undefined)
{
    if(user == undefined)
    {
        localStorage.removeItem(key);
    }
    else
    {
        localStorage.setItem(key, JSON.stringify(user));
    }
}

export function RemoveUserLocalStorage()
{
    localStorage.removeItem(key);
}


export function GetUserLocalStorage()
{
    const json = localStorage.getItem(key);

    if(!json)
    {
        return null
    }

    const user = JSON.parse(json);
    return user ?? null;
}

export function SetOrganizationNameLocalStorage(organizationName: string | undefined)
{
    localStorage.setItem('organizationNameLS', JSON.stringify(organizationName));
    if(organizationName == null)
    {
        localStorage.removeItem('organizationNameLS');
    }
}

export function GetOrganizationNameLocalStorage()
{
    const json = localStorage.getItem('organizationNameLS');

    if(!json)
    {
        return null
    }

    const organizationName = JSON.parse(json);
    return organizationName ?? null;
}

export function LoginRequest(email: string, password: string){

    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('client_id', 'resourceownerclient');
    params.append('client_secret', 'greendocs');
    params.append('scope', 'greendocs');
    params.append('username', email);
    params.append('password', password);

    return ApiAuth.post<any>('connect/token',
        params
    )
}

export function LoginRequestMFA(otpCode: string, email: string) {
    const params = new URLSearchParams();
    params.append('grant_type', 'multifactoGrant');
    params.append('client_id', 'multifactorClient');
    params.append('client_secret', 'greendocs');
    params.append('scope', 'greendocs');
    params.append('username', email);
    params.append('password', otpCode);

    return ApiAuth.post<any>('connect/token',
        params
    )
}

export function LoginRequestMS(token: string) {
    const params = new URLSearchParams();
    params.append('grant_type', 'microsoftGrant');
    params.append('client_id', 'microsoftClient');
    params.append('client_secret', 'greendocs');
    params.append('scope', 'greendocs');
    params.append('token', token);

    return ApiAuth.post<any>('connect/token',
        params
    )
}