export interface DocxConfig {}

export const Docx: React.FC<DocxConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .docx-1 {
              fill: #fff;
            }

            .docx-2 {
              fill: #7cc9f9;
            }

            .docx-2, .docx-3 {
              fill-rule: evenodd;
            }

            .docx-3 {
              fill: #067fcb;
            }

            .docx-4 {
              fill: none;
              isolation: isolate;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_748" data-name="Grupo 748">
        <g id="Grupo_733" data-name="Grupo 733">
          <path id="Caminho_157" data-name="Caminho 157" className="docx-3" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_159" data-name="Caminho 159" className="docx-2" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="docx" className="docx-4">
          <g className="docx-4">
            <path className="docx-1" d="m12.4,34.97h-1.59v-1.19h-.03c-.51.9-1.3,1.35-2.36,1.35-.86,0-1.55-.31-2.07-.94-.52-.63-.78-1.48-.78-2.56,0-1.16.29-2.08.86-2.78s1.33-1.05,2.29-1.05,1.63.38,2.06,1.13h.03v-4.33h1.59v10.36Zm-1.57-3.2v-.92c0-.5-.16-.92-.49-1.26s-.74-.52-1.24-.52c-.59,0-1.06.22-1.4.66s-.51,1.05-.51,1.84c0,.71.16,1.27.49,1.68.33.41.76.62,1.32.62s.98-.2,1.32-.6c.34-.4.51-.9.51-1.51Z"/>
            <path className="docx-1" d="m17.57,35.13c-1.08,0-1.94-.33-2.59-.98-.65-.65-.97-1.52-.97-2.6,0-1.18.34-2.09,1.01-2.75s1.58-.99,2.72-.99,1.95.32,2.56.96.92,1.53.92,2.67-.33,2.01-.99,2.68c-.66.67-1.54,1.01-2.66,1.01Zm.08-6.06c-.62,0-1.11.22-1.47.65s-.54,1.03-.54,1.79.18,1.31.55,1.73c.36.42.85.63,1.46.63s1.1-.21,1.44-.62.5-1,.5-1.77-.17-1.37-.5-1.78-.81-.63-1.44-.63Z"/>
            <path className="docx-1" d="m27.84,34.65c-.56.32-1.22.49-1.99.49-1.04,0-1.88-.32-2.52-.97-.64-.65-.96-1.49-.96-2.53,0-1.15.34-2.08,1.03-2.78.69-.7,1.6-1.05,2.75-1.05.64,0,1.2.11,1.69.33v1.48c-.49-.36-1.01-.55-1.56-.55-.67,0-1.22.23-1.65.68-.43.45-.64,1.05-.64,1.78s.2,1.3.6,1.72c.4.42.95.63,1.62.63.57,0,1.11-.2,1.61-.61v1.38Z"/>
            <path className="docx-1" d="m35.26,27.97l-2.33,3.54,2.3,3.46h-1.84l-1.23-2.17c-.08-.13-.17-.3-.27-.51h-.03s-.11.21-.29.51l-1.25,2.17h-1.83l2.38-3.43-2.26-3.57h1.85l1.21,2.28c.09.17.18.34.27.52h.02l1.57-2.8h1.74Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
