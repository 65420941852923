import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LoaderComp from "../../components/Loader/LoaderComp";
import { DownloadViwer, GetOneItem } from "../../services/Item/ItemServicesGet";
import ChoicerViwer from "../WorkSpaceItem/Viwer/ChoicerViwer";

const LinkToFilePage = (props: any) => {
    const { t } = useTranslation();
    const { idFile } = useParams();
    const [name, setName] = useState<string | undefined>(undefined);
    const [extension, setExtension] = useState<string | undefined>(undefined);

    const [error, setError] = useState<string | undefined>(undefined);
    const [wait, setWait] = useState<boolean>(false);

    useEffect(() => {
        if (idFile != undefined) {
            setWait(true);
            GetOneItem(idFile)
                .then((result) => {
                    setName(result.name);
                    setExtension(result.extension);
                })
                .catch((erro) => {
                    setError(t("WithoutPermission.message"));
                })
                .finally(() => {
                    setWait(false);
                });
        }
        else {

        }
    }, []);

    return (
        <div>
            {idFile != undefined && (
                wait ?
                    (
                        <LoaderComp big={true} />
                    ) : error == undefined &&
                    (
                        <ChoicerViwer
                            idItem={idFile}
                            download={DownloadViwer}
                            getItem={GetOneItem}
                            positionAbsolute={true}
                            title={`${name}.${extension}`}
                            isFilePage={true}
                            fullScreenSetted={false}
                            isAcept={false}
                            isSharedFilePage={false}
                        />
                    )
            )}

            {idFile == undefined || error != undefined && (
                <div>
                    {error != undefined ? error : t("ItemIdNotFound.message")}
                </div>
            )}
        </div>
    );
};

export default LinkToFilePage;
