import React, { useState, useEffect } from "react";
import CheclkBoxComp  from "../../../components/Checkbox/CheckboxComp";
import MaskedTextFieldComp from "../../../components/TextField/MaskedTextFieldComp";
import { useBoolean } from "@fluentui/react-hooks";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import  Btn  from '../../../components/Button/Btn';
import IframeD4sign from "./IframeD4sign"
import { useTranslation } from "react-i18next";
import Message from "../../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    ContextualMenu,
    Toggle,
    Modal,
    IDragOptions,
    IIconProps,
    Stack,
    IStackProps,
  } from '@fluentui/react';
  import Snackbar from '@mui/material/Snackbar';
  import MuiAlert, { AlertColor } from '@mui/material/Alert';
  import {
    WorkSpaceUserName
  } from "../../../model/ItemModel";
  import {
    ForwardItemVO, ValidationInformation
  } from "../../../model/ValidationRuleModel";

export interface SignMainInfo{
    fileKey: string;
    signer_email: string;
    signer_display_name: string;
}

export interface SignMainParams{
    forwardItemVO:ForwardItemVO;
    signMainInfo:SignMainInfo | undefined;
    hideDialog:boolean;
    setHide: (hideDialog:boolean) => void;
    setPopupOkRefresh: (set:boolean, message:string, item: WorkSpaceUserName) => void;
    forwardItem: (fowardSet:ForwardItemVO) => Promise<ValidationInformation>;
    setSnackbarType?: (message: string, severity: AlertColor) => void
}

const SignMain = (props: SignMainParams) => {
    const [cpf, setCpf] = useState<string| undefined>();
    const [isAbroad, {setTrue: isAbroadT, setFalse: isAbroadF}] = useBoolean(false);
    const [hideDialog, setHideDialog] = useState<boolean>(props.hideDialog);
    const [cpfValid, setCpfValid] = useState<boolean>(false);
    const { t, i18n } = useTranslation();
    const [isMessageOk, setMessageOk] = useState<boolean>(false);
    const [popupOk, setPopupOk] = useState<boolean>(false);
    const [erroMessage, setErroMessage] = useState<string>("");

    const handleClose = (event:any, reason:any) => {
        closePopUpOk();
    }
    
    const handleClosePO = (event:any) => {
        closePopUpOk();
    }

    function closePopUpOk() {
        setPopupOk(false);
    }

    useEffect(() => {
        if(props.hideDialog)
        {
            setCpf(undefined);
            setPopupOk(false);
            setCpfValid(false);
            setMessageOk(false);
        }
        setHideDialog(props.hideDialog);
      }, [props.hideDialog]);

    const onChangesetCpf = React.useCallback(
        (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
        ) => {
            setCpf(newValue);
        },
        []
    );

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key ===  'Enter'){
            sentSign();
        }
    }

    function sentSign()
    {
        if((cpf != undefined && validateCPF(cpf)) || isAbroad)
        {
            setMessageOk(false);
            setCpfValid(true);
        }
        else
        {
            setMessageOk(true);
        }
    }

    const validateCPF = (cpf:string) => {
        cpf = cpf.replace('.','');
        cpf = cpf.replace('.','');
        cpf = cpf.replace('-','');
        if(cpf.length != 11)
        {
            return false;
        }

        if (cpf == "00000000000"){
            return false;
        }

        let Soma:number = 0;
        var Resto:number = 0;
            Soma = 0;

        for (let i=1; i<=9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;
        
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(cpf.substring(9, 10)) ) return false;
        
        Soma = 0;
        for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;
        
        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(cpf.substring(10, 11) ) ) return false;
        return true;

    }


    function changeIsAbroad (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if(checked == undefined || checked == false)
        {
            isAbroadF();
        }
        else
        {
            isAbroadT();
        }
      };

      const dialogContentProps = {
        type: DialogType.normal,
        title: t("CPF.choice"),
        closeButtonAriaLabel: t("Fechar.message")
      };

      function cancel()
      {
        setHideDialog(true);
        props.setHide(true);
      }

    function showErrorMessage(error: string)
    {
        setErroMessage(error);
        setPopupOk(true);
    }

    function ForwardItemInternal()
    {
      props.forwardItem(props.forwardItemVO)
      .then((itemReturn) =>{
        cancel();
        if (props.setSnackbarType != undefined)
        {
            props.setSnackbarType(t("AssinadoComSucesso.message"), "success");
        }
        props.setPopupOkRefresh(true, t("AssinadoComSucesso.message"), itemReturn.itemReturnVOName);
      })
      .catch((error) =>
      {
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
            showErrorMessage( error.response.data);
        }
        else
        {
            showErrorMessage( t("ValidationRule.error"));
        }
      });
    }

    return (
        <div>
            <Modal
                titleAriaId={t("CPF.choice")}
                isOpen={hideDialog == false}
                onDismiss={cancel}
                isBlocking={false}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.body}>
                    {cpfValid == false || props.signMainInfo == undefined?(
                        <div>
                            <MaskedTextFieldComp
                                label={"CPF"}
                                value={cpf}
                                onChange={onChangesetCpf}
                                onKeyDown={onKeyDown} 
                                disabled={isAbroad}
                                mask={"999.999.999-99"}
                            />

                            <CheclkBoxComp 
                                label={ t("foreign.message")}
                                checked={isAbroad}
                                onChange={changeIsAbroad}
                            />

                            {isMessageOk && (
                                <Message text={t("CPF.invalid")} type={MessageBarType.error} />
                            )}
                        </div>
                    ):(
                        <div>
                            <IframeD4sign
                                fileKey={props.signMainInfo.fileKey}
                                signer_email={props.signMainInfo.signer_email}
                                signer_display_name= {props.signMainInfo.signer_display_name}
                                signer_documentation={cpf}
                                showErrorMessage={showErrorMessage}
                                ForwardItemInternal={ForwardItemInternal}
                            />
                        </div>
                    )}
                    <Btn onClick={cancel} text={t("Cancelar.message")} />
                    {(cpfValid == false || props.signMainInfo == undefined) && (
                        <Btn onClick={sentSign} text={t("CPF.nextStep")} />
                    )}
                </div>
                <Snackbar
                    open={popupOk}
                    autoHideDuration={6000}
                    message="Archived"
                    onClose={handleClose}
                    anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                    sx={{ bottom: { xs: 50, sm: 20 } }}
                    >
                    <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={"error"} onClose={handleClosePO}>
                        {erroMessage}
                    </MuiAlert>
                </Snackbar>
            </Modal>
        </div>
    )
}

const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
          p: { margin: '14px 0' },
          'p:first-child': { marginTop: 0 },
          'p:last-child': { marginBottom: 0 },
        },
      }
})

export default SignMain;
