import React, { ChangeEvent, useEffect, useState, useRef, useCallback } from "react";
import {
    TrakkerList
  } from '../../../model/ItemModel';
  import moment from 'moment';
  import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
  import { useId } from '@fluentui/react-hooks';


interface WorkSpaceItemListFileTrackerRevisionActivityProps {
    trakkerList:TrakkerList;
    enumerate:number;
}

const WorkSpaceItemListFileTrackerRevisionActivity: React.FC<WorkSpaceItemListFileTrackerRevisionActivityProps> = ({
    trakkerList,
    enumerate
}) => {
    const tooltipId = useId('tooltip');
    const calloutProps = { gapSpace: 0 };

    return (
        <li
            style={{
                display:"inline-flex",
                width:"100%",
                borderLeft:" 1px solid ",
                borderRight: "1px solid",
                borderBottom: "1px solid",
            }}
        >
            <div style={{width:"9%"}}>
                {enumerate}    
            </div>
           

            <TooltipHost 
                content={trakkerList.message?trakkerList.message:""}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={{
                    root:{
                        width:"65%",
                        wordWrap:"break-word",
                        overflow:"hidden",
                        whiteSpace:"nowrap",
                        textOverflow: "ellipsis"
                    }
                }}>
                {(trakkerList.message == undefined || trakkerList.message == "") ? "...":trakkerList.message}

            </TooltipHost>
            <TooltipHost 
                content={trakkerList.nameUser}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={{
                    root:{
                        width:"10%",
                        wordWrap:"break-word",
                        overflow:"hidden",
                        whiteSpace:"nowrap",
                        textAlign:"center",
                        textOverflow: "ellipsis"
                    }
                }}>
                {trakkerList.nameUser}
            </TooltipHost>
            <TooltipHost 
                content={trakkerList.ipUser}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={{
                    root:{
                        width:"10%",
                        wordWrap:"break-word",
                        overflow:"hidden",
                        whiteSpace:"nowrap",
                        textAlign:"center"
                    }
                }}>
                {trakkerList.ipUser}
            </TooltipHost>
            <TooltipHost 
                content={moment(trakkerList.trackerDateTime).format('DD/MM/YYYY HH:mm')}
                id={tooltipId}
                calloutProps={calloutProps}
                styles={{
                    root:{
                        width:"15%",
                        wordWrap:"break-word",
                        overflow:"hidden",
                        whiteSpace:"nowrap",
                        textAlign:"center"
                    }
                }}>
                {moment(trakkerList.trackerDateTime).format('DD/MM/YYYY HH:mm')}
            </TooltipHost>
        </li>
    );
}

export default WorkSpaceItemListFileTrackerRevisionActivity;