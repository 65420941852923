import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { ReactElement } from "react"
import Btn, { TypeStyle } from "./Button/Btn";
import { useTranslation } from "react-i18next";

export interface IDialogComponent {
    children: any;
    isOpen: boolean;
    titleContent: string;
    onClickConfirm: () => void;
    onClickCancel: (set: boolean) => void;
    isOtpDialog: boolean;
    isCancelButtonNeeded?: boolean;
}

export const DialogComponent = ({
    children,
    isOpen,
    titleContent,
    onClickCancel,
    onClickConfirm,
    isOtpDialog,
    isCancelButtonNeeded
}: IDialogComponent) => {

    const { t } = useTranslation();

    return (
        <Dialog
        open={isOpen}
    >
        <DialogTitle id="alert-dialog-title">
            <Typography
                component='label'
                sx={{
                    textAlign: "left",
                    font: "normal normal 600 20px/28px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    marginBottom: '10px'
                }}
            >
                {titleContent}
            </Typography>
        </DialogTitle>
        <DialogContent                 
            sx={{
                display: "flex",
                flexDirection: "column"
            }}
        >
            { children }
        </DialogContent>
        <DialogActions>
        <Btn 
            style={TypeStyle.saveButton}
            text={t("Confirmar.message")}
            onClick={() => onClickConfirm()}
        />
        { isCancelButtonNeeded ? (
            <Btn 
                style={TypeStyle.saveButton}
                text={t("Cancelar.message")}
                onClick={() => onClickCancel(isOtpDialog)}
            />
        ) : (
            <></>
        ) }
        </DialogActions>
    </Dialog>
    )
}