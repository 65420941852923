import { useState, useEffect } from 'react';
import { ItemValidationRule } from '../../../model/ItemModel';

export function UseStatus(
  isReleaseConst: boolean, 
  validationRuleConst: ItemValidationRule | undefined, 
  itemType: string, 
  batchWaiter: boolean | undefined, 
  archiving: boolean | undefined, 
  expired: boolean | undefined
) {
  if(expired == true)
  {
    return("Expired");
  }
  else if(archiving == true){
    return("Filed");
  }
  else{
    if (isReleaseConst) {
      if (itemType === "folder") {
        return("---");
      }
      else {
        return("Release");
      }
    }
    else if(validationRuleConst !== undefined) {
      if(validationRuleConst.validationRuleDeleted === false) {
        if(itemType == "folder") {
          return("---");
        } 
        else {
          if(validationRuleConst?.currentOperation){
            return("Flow");
          }
        }
      }
      else{
        return("Removido");
      }
    }
    else{
      if(itemType == "folder"){
        return("---");
      }
      else{
        return("Draft");
      }
    } 

    return "";
  }
}

export function UseStatusWithCurrentOperation(
  isReleaseConst: boolean, 
  validationRuleConst: ItemValidationRule | undefined, 
  itemType: string, 
  batchWaiter: boolean | undefined, 
  archiving: boolean | undefined, 
  expired: boolean | undefined
) {

    const [status, setStatus] = useState<string>("");

    function validationRuleValidation() {
      setStatus(UseStatus(isReleaseConst, validationRuleConst, itemType, batchWaiter, archiving, expired));
    }

    function getCurrentOperation(): string | undefined {
      return validationRuleConst?.currentOperation;
    }

    useEffect(() => {
        validationRuleValidation();
    }, [isReleaseConst, validationRuleConst, batchWaiter])

    return {
        currentStatus: status,
        currentOperation: getCurrentOperation()
    }
}