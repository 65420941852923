import { NavigateFunction } from "react-router-dom";
import { RemoveOrganizationLocalStorage, RemoveWorkspaceLocalStorage, GetOrganizationLocalStorage, GetWorkspaceLocalStorage } from "./RedirectManager";

export function RedirectNavigate(navegate: NavigateFunction){
    const organizationLocalStorage = GetOrganizationLocalStorage();
    const workspaceLocalStorage = GetWorkspaceLocalStorage();
  
    if(organizationLocalStorage != null && workspaceLocalStorage != null){
      navegate("/" + organizationLocalStorage + "/wsallowed/" +  workspaceLocalStorage);
    }
    else if (organizationLocalStorage != null && workspaceLocalStorage == null){
      navegate("/" + organizationLocalStorage + "/wsallowed");
    } else {
      navegate("/");
    }
  }