import React, {useState, useEffect, KeyboardEventHandler, ComponentType} from "react";
import {
    mergeStyleSets,
} from '@fluentui/react';
import {Color} from "../../../assets/icons/interface/Color";

export const imageStyle = mergeStyleSets({
    img:
    {
        width:"18px",
        height:"18px",
        marginRight:"3px",
        marginLeft:"3px"
    }
})


export enum ButtonTyppe {
    primary,
    secondary
}

export interface BtnBigWithIconConfig{
    text?:string;
    icon?: ComponentType<Color>;
    src?:string;
    isPrimary?:ButtonTyppe;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    display?:boolean |undefined
    disabled?:boolean |undefined
}

function getLabelColor(disabled?:boolean |undefined,isPrimary?:ButtonTyppe )
{
    if(isPrimary == ButtonTyppe.secondary ) {
        if(disabled == true)
            return "#ccdeec";
        else
            return "#005A9E";
    }
    else
    {
        return "white";
    }
}

function getBackGroundColor(disabled?:boolean |undefined,isPrimary?:ButtonTyppe )
{
    if(isPrimary == ButtonTyppe.secondary ) {
        return "white";
    }
    else
    {
        if(disabled == true)
            return "#ccdeec";
        else
            return "#005A9E";
        
    }
}

const BtnBigWithIcon = (props: BtnBigWithIconConfig) => {

    const contentStyles = mergeStyleSets({
        btn:{
            width: "100%",
            height: "54px",
            marginTop:"5px",
            marginBotton:"5px",
            backgroundColor: getBackGroundColor(props.disabled,props.isPrimary),
            cursor: "pointer",
            borderStyle: props.disabled != true ?"solid" : undefined,
            borderWidth: "1px",
            borderRadius: "8px",
            borderColor: props.isPrimary == ButtonTyppe.secondary ? "#005A9E":"white",
            ':hover':{
                backgroundColor: getBackGroundColor(props.disabled,props.isPrimary),
                color: props.isPrimary == ButtonTyppe.secondary ? "#005A9E" :"white",
                borderColor: "#005A9E",
                borderStyle: props.disabled != true ?"solid" : undefined,
                borderWidth: "2px"
            },
        },
        label:
        {
            //color: props.isPrimary == ButtonTyppe.secondary ? "#005A9E" :"white",
            color:getLabelColor(props.disabled,props.isPrimary),
            fontWeight: 'bold',
            font:"600 20px / 20px Segoe UI"
        }
    })

    if(props.display != undefined && props.display == true)
    {
        return null;
    }

    return(
        <button
            className={contentStyles.btn} 
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <span className={contentStyles.label} >
                {props.icon != undefined && (
                    <props.icon
                        color={getLabelColor(props.disabled,props.isPrimary)}
                    />
                )}
                {props.src != undefined &&(
                    <img 
                        src={props.src} 
                        width={20} 
                    />
                )}
                {props.text != undefined && (props.text)}
            </span>
        </button>
    )
}

export default BtnBigWithIcon;