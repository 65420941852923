import { Paper, useTheme } from '@mui/material'

interface LeftHandlerProps {
    children: any;
}

export function LeftHandler({
    children
}: LeftHandlerProps) {

    const theme = useTheme();

    return (
        <Paper
            sx={{
                width: '600px',
                padding: '44px',
                fontFamily: 'Segoe UI',
                marginTop:"auto",
                marginLeft: "180px",
                borderRadius: "25px",

                [theme.breakpoints.down("lg")]: {
                    margin: 'auto',
                    marginTop: '10px',
                    marginBottom: '10px',
                    border:"2px solid rgb(45, 45, 45)"
                },
                [theme.breakpoints.down("md")]: {
                    marginLeft: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    width: '100%',
                    border:"0px solid rgb(45, 45, 45)"
                }
            }}
        >
            { children }
        
        </Paper>
    )
}