export interface MppConfig {}

export const Mpp: React.FC<MppConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .mpp-1 {
              fill: #fff;
            }

            .mpp-2 {
              fill: none;
              isolation: isolate;
            }

            .mpp-3 {
              fill: #b3b3b3;
            }

            .mpp-3, .mpp-4 {
              fill-rule: evenodd;
            }

            .mpp-4 {
              fill: #d6d6d6;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_756" data-name="Grupo 756">
        <g id="Grupo_741" data-name="Grupo 741">
          <path id="Caminho_136" data-name="Caminho 136" className="mpp-3" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_138" data-name="Caminho 138" className="mpp-4" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="mpp" className="mpp-2">
          <g className="mpp-2">
            <path className="mpp-1" d="m16.5,34.97h-1.59v-3.81c0-.73-.1-1.26-.31-1.59s-.56-.49-1.05-.49c-.41,0-.77.21-1.06.62s-.43.91-.43,1.49v3.79h-1.59v-3.94c0-1.3-.46-1.96-1.38-1.96-.43,0-.78.2-1.06.59s-.41.9-.41,1.52v3.79h-1.59v-7h1.59v1.11h.03c.51-.85,1.24-1.27,2.21-1.27.48,0,.91.13,1.27.4.36.27.61.62.74,1.05.52-.97,1.29-1.45,2.32-1.45,1.54,0,2.31.95,2.31,2.85v4.31Z"/>
            <path className="mpp-1" d="m20.05,33.94h-.03v4.25h-1.59v-10.22h1.59v1.23h.03c.54-.93,1.33-1.39,2.38-1.39.89,0,1.58.31,2.08.94s.75,1.46.75,2.52c0,1.17-.28,2.1-.84,2.8s-1.33,1.05-2.3,1.05c-.89,0-1.58-.39-2.06-1.18Zm-.04-2.76v.87c0,.51.16.94.49,1.3s.74.53,1.24.53c.59,0,1.05-.23,1.38-.69.33-.46.5-1.1.5-1.92,0-.69-.16-1.23-.47-1.62-.31-.39-.73-.58-1.27-.58-.56,0-1.02.2-1.36.6s-.52.9-.52,1.5Z"/>
            <path className="mpp-1" d="m28.48,33.94h-.03v4.25h-1.59v-10.22h1.59v1.23h.03c.54-.93,1.33-1.39,2.38-1.39.89,0,1.58.31,2.08.94s.75,1.46.75,2.52c0,1.17-.28,2.1-.84,2.8s-1.33,1.05-2.3,1.05c-.89,0-1.58-.39-2.06-1.18Zm-.04-2.76v.87c0,.51.16.94.49,1.3.33.36.74.53,1.24.53.59,0,1.05-.23,1.38-.69.33-.46.5-1.1.5-1.92,0-.69-.16-1.23-.47-1.62-.31-.39-.73-.58-1.27-.58-.56,0-1.02.2-1.36.6-.34.4-.52.9-.52,1.5Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
