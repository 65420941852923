import { Typography, Box } from '@mui/material';

export interface FormHeaderProps {
    title: string;
    subTitle?: string | undefined;
    titleSize?: number;
}

export default function FormHeader({ title, subTitle, titleSize }: FormHeaderProps) {

    return (
        <>
            <Typography
                fontSize={titleSize !== undefined ? titleSize : 53}
                fontWeight={600}
                letterSpacing={0}
                color="#2D2D2D"
            >
                { title }
            </Typography>

            {subTitle != undefined && (
                <Typography
                    fontSize={20}
                    fontWeight="normal"
                    letterSpacing={0}
                >
                    { subTitle }
                </Typography>
            )}
        </>
    )
}