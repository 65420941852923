import { Link, SxProps, Theme } from "@mui/material"

interface FormLinkProps {
  link: string
  href: string
  underline?: "none" | "hover" | "always" | undefined
  sx?: SxProps<Theme> | undefined
}

export function FormLink({ link, href, underline, sx }: FormLinkProps) {
  return (
    <Link
      underline={underline}
      href={href}
      sx={sx}
    >
      { link }
    </Link>
  )
}