import { Box, Grid } from '@mui/material';
import FormContextText from './FormContextText/FormContextText';
import FormActionLink from './FormActionLink/FormActionLink';

interface FormActionsProps {
    contextText?: string;
    text?: string;
    href?: string;
    isRenderingArrow?: boolean;
    isRenderingFormActions?: boolean;
}

export default function FormActions({
    href,
    isRenderingArrow,
    text,
    contextText,
}: FormActionsProps) 
{
    return (
        <Grid item xs={12}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2
            }}>
                <FormContextText 
                    contextText={contextText}
                />
                <FormActionLink 
                    href={href}
                    text={text}
                    isRenderingArrow={isRenderingArrow}
                />
            </Box>
        </Grid>
    )
}