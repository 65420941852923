import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {  TextField } from "@fluentui/react";
import { SetUserTwofaCode } from "../../../services/UserServices";
import { UserTwoFaCode } from "../../../model/UserModel";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import codeAccess from '../../../assets/icons/acesso-por-codigo.svg'
import { Grid, Typography, useTheme } from "@mui/material";
import {LeftForm} from "../../PublicLayout/components/Handle/LeftForm";
import FormErrors from "../../PublicLayout/components/Handle/Form/Root/components/FormErrors/FormErrors";
import FormButton from "../../PublicLayout/components/Handle/Form/Root/components/FormButton/FormButton";
import BtnBigWithIcon,{ ButtonTyppe} from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";

const TwoFaComp = (props: any) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>("");
  const [isChecked, setCheckedState] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [disable, setDisable] = useState<boolean>(false);
  const navegate = useNavigate();
  const [popupOk, setPopupOk] = useState<boolean>(false);
  const theme = useTheme();

  const showPopupOk = () => {
    setPopupOk(true);
    setDisable(true);
  };

  function closePopUpOk() {
    setPopupOk(false);
  }

  const handleClose = (event: any, reason: any) => {
    closePopUpOk();
  };

  const handleClosePO = (event: any) => {
    closePopUpOk();
  };

  const login = (event: any) => {
    navegate("/login");
  };

  const sendCode = (event: any) => {
    event.preventDefault();

    if (code.trim() == "" ) {
      setMessage(t("UsuarioOuCodeIncorretors.message"));
      setCheckedState(true);
      return;
    }

    setCheckedState(false);
    setLoad(true);

    let userTwoFaCode: UserTwoFaCode = {
      userTwoFaCode: code
    };

    SetUserTwofaCode(userTwoFaCode)
      .then(() => {
        setCheckedState(false);
        setLoad(false);
        showPopupOk();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          typeof error.response.data === "string"
        ) {
          setMessage(error.response.data);
        } else {
          setMessage(t("UsuarioOuCodeIncorretors.message"));
        }

        setCheckedState(true);
        setLoad(false);
      });
  };

  const onChangeCode = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setCode(newValue);
    } else {
      setCode("");
    }
  };

  return (
    <>
      <LeftForm
        formRootInstance={{
          title:t("Login.accessAcount"),
          subTitle:t("Login.helloAgain"),
          iconToRender:codeAccess
        }}
      >
        <Grid container sx={{
          gap: 4
        }}>
          <Grid item xs={12} sx={{
              marginTop: '32px'
          }}>
            <Typography
                component='label'
                sx={{
                    textAlign: "left",
                    font: "normal normal 600 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    marginBottom: '10px'
                }}
                >
                {t("Login.yourAccessCode")}
            </Typography>
            <TextField 
                onChange={onChangeCode}
                value={code}
                styles={{
                    fieldGroup: {
                    background: "#FBFBFB",
                    border: "2px solid #2D2D2D4D",
                    borderRadius: "8px",
                    opacity: 1,
                    height: '54px'
                    }
                }}
                type='password'
                placeholder={t("Login.typeYourAccessCode")}
                onFocus={() => {
                  setCheckedState(false);
                }}
            />
          </Grid>
          <FormErrors 
            messagePass={message}
            isChecked={isChecked}
          />
          <FormButton 
            load={load}
            btnBigWithIconConfig={{
              text:t("Enviar.message"),
              onClick:sendCode
            }}
          />
          <BtnBigWithIcon 
            isPrimary={ButtonTyppe.secondary}
            text={t("IrParaLogin.message")}
            onClick={login}
          
          />
        </Grid>

      </LeftForm>
      <Snackbar
        open={popupOk}
        autoHideDuration={6000}
        message="Archived"
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ bottom: { xs: 50, sm: 20 } }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          sx={{ width: "100%" }}
          severity={"success"}
          onClose={handleClosePO}
        >
          {t("Code.acepted")}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default TwoFaComp;
