import { Box } from "@mui/material";
import { WorkSpaceUserName } from "../../../../../model/ItemModel";
import loupe from '../../../../../assets/icons/search-lupa.svg';
import {haveDisplay} from "../../HaveDisplayFunction"

interface ILoupe {
    workspaceItem: WorkSpaceUserName;
    onClickExibirViewer () : void;
    downloadFile () : void;
}

export default function Loupe({ 
    workspaceItem,
    onClickExibirViewer,
    downloadFile
}: ILoupe) {
    return (
        <>
            {workspaceItem.itemType === 'folder' ? (
                <Box
                    component="img"
                    src={loupe}
                    sx={{
                        opacity: .5
                    }}
                />
            ) : (
                <Box
                    component="img"
                    src={loupe}
                    sx={{
                        cursor: "pointer"
                    }}
                    onClick={haveDisplay(workspaceItem.extension) ? onClickExibirViewer : downloadFile}
                />
            )}
        </>
    )
}