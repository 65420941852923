import React, { useEffect, useRef, useState, useCallback  } from "react";
import {
    ComboBox, IComboBox,
    IComboBoxOption, IComboBoxStyles, ISearchBox, ISearchBoxStyleProps, ISearchBoxStyles, IStyleFunctionOrObject, SearchBox, mergeStyleSets
} from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import PrintIcon from '@mui/icons-material/Print';
import { Alert, Snackbar } from "@mui/material";
import * as PdfJs from "pdfjs-dist";
import printJS from 'print-js';

import { useTranslation } from "react-i18next";
import Loader from "react-ts-loaders";
import { ViwerProps } from "../../../pages/WorkSpaceItem/Viwer/ViwerProps";
import styles from "../../PDF/PDFViewer/PDFPageViwer.module.css";
import HeaderViewerComp from "../../Viewer/Header/HeaderViewerComp";
import SidebarViewerComp from "../../Viewer/Sidebar";
import LazyPreview from "../../Viewer/Sidebar/ViewCurrent/CurrentPdfPreview/LazyPreview";
import LazyViewer from "./Main/LazyViewer";
import { ReactComponent as MoreIcon } from './SVG/adicionar.svg';
import { ReactComponent as ArrowIcon } from './SVG/arrow-down.svg';
import { ReactComponent as LessIcon } from './SVG/menos-linha.svg';
import { ReactComponent as SearchIcon } from './SVG/search-lupa.svg';

const options: IComboBoxOption[] = [
    { key: 0.5, text: '50%' },
    { key: 1, text: '100%' },
    { key: 1.5, text: '150%' },
    { key: 2, text: '200%' },
    { key: 2.5, text: '250%' },
    { key: 3, text: '300%' },
    { key: 3.5, text: '350%' },
    { key: 4, text: '400%' },
    { key: 4.5, text: '450%' },
    { key: 5, text: '500%' },
];

const comboBoxStyles: Partial<IComboBoxStyles> = { 
    root: { 
        width:77,
        height:22,
        backgroundColor: "#F9F9F9"
    },
    input: {
        backgroundColor: "#F9F9F9"
    }
};

const searchBoxStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = {
    root: {
        borderRadius: '10px',
        "::after": {
            border: "none",
        } 
    },
    icon: {
        color: "#000"
        
    }
}

const PDFView  = (props: ViwerProps) => {
    const { t } = useTranslation();
    const canvasMainRef = useRef<HTMLDivElement>(null);
    const refButton = useRef<HTMLButtonElement | null>(null);
    const canvasRefList = useRef(new Array())
    const comboBoxRef = React.useRef<IComboBox>(null);
    const scrollViewerRef = useRef<HTMLDivElement>(null)
    const searchBoxRef = useRef<HTMLDivElement>(null);
    const searchBoxComponentRef = useRef<ISearchBox | null>(null);
    const [page, setPage] = useState<number>(1);
    const [scale, setScale] = useState(1);
    const [textPdfList, setTextPdfList] = useState<(HTMLCollection | Element[] | undefined)[]>([]);
    const [keySelect, setKeySelect] = useState<string | number>(1);
    const [searchText, setSearchText] = useState<string>();
    const [highlightWords, setHighlightWords] = useState<Element[]>();
    const [currentSearchIndex, setCurrentIndexSearch] = useState<number>(-1);
    const [documentSize, setDocumentSize] = useState<{
        width: number;
        height: number;
    }>();
    const [canRenderPageList, setCanRenderPageList] = useState<number[]>([1])
    const [pdfPageList, setPdfPageList] = useState<PdfJs.PDFPageProxy[]>([])
    const [hideSearch, { toggle: toggleHideSearch }] = useBoolean(true);
    const [pdfDocument, setPdfDocument] = useState<PdfJs.PDFDocumentProxy>()
    const [loader, setLoader] = useState<boolean>(true);
    const [inputHighlightHasBeenChanged, setInputHighlightHasBeenChanged] = useState<boolean>(false);

    PdfJs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${PdfJs.version}/legacy/build/pdf.worker.min.js`;

    useEffect(() =>{
        if(scale != null){
            setKeySelect(scale)
        }

        setTextPdfList([])

        const firstPageLoaded = pdfPageList.find(pdfPage => pdfPage.pageNumber == 1)
        if(firstPageLoaded){
            updateFirstPageSize(firstPageLoaded)
        }
    },[scale])

    useEffect(() => {
        if (!props.isDetailPage && !props.isAcept)
        {
            if (refButton.current != null)
            {
                refButton.current.focus();
                refButton.current.style.outline = "none";
                refButton.current.addEventListener("keydown", e => {
                    if (e.key === "f" && e.ctrlKey || e.keyCode === 114)
                    {
                        toggleHideSearch();
                        e.preventDefault();
                    }
                });
            }
        }
    }, [refButton.current]);

    useEffect(() => {
        if (canvasRefList.current[page - 1]) {
            handleScroll(canvasRefList.current[page - 1]);
        }
    }, [page]);
    

    const onSearchHighlights = () => {
        if(textPdfList){
            const textPdf: HTMLCollection | Element[] | undefined = textPdfList.reduce((prev, curr) => {
                return Array.from(prev!).concat(Array.from(curr!))
            }, []);
            
            if(textPdf){
                Array.from(textPdf).forEach(span => {
                    if(span instanceof HTMLSpanElement){
                        const fatherElement = span.parentElement;
                        if(fatherElement){
                            fatherElement.style.opacity = "0.2" 
                        }
                        span.style.background = "none"
                        span.style.color = "transparent"
                    }
                })

        if (
          searchText !== undefined &&
          searchText !== "" &&
          searchText !== " "
        ) {
                    let wordsFiltered;

                    let currentIndex = -1;

                    wordsFiltered = Array.from(textPdf).filter(span => span.textContent?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))

                    if(wordsFiltered.length > 0){
                        wordsFiltered = wordsFiltered.sort((a: Element, b: Element) => {
                            const rectA = a.getBoundingClientRect();
                            const rectB = b.getBoundingClientRect();
                        
                            const diffY = Math.round(rectA.y) - Math.round(rectB.y);
                            if (diffY < 10 && diffY > -10) {
                                return rectA.x - rectB.x
                            }
                        
                            return rectA.y - rectB.y;
                        })
                        
                        const currentWordsFiltered = wordsFiltered.reduce((prev, curr) => {
                            return (Math.abs(curr.getBoundingClientRect().y - (canvasMainRef?.current?.scrollTop ?? 0)) < Math.abs(prev.getBoundingClientRect().y - (canvasMainRef?.current?.scrollTop ?? 0)) ? curr : prev);
                        });  
                        

            currentIndex = wordsFiltered.indexOf(currentWordsFiltered);
          }

          wordsFiltered.forEach((word, index) => {
            if (word instanceof HTMLSpanElement) {
              const fatherElement = word.parentElement;
              if (fatherElement) {
                fatherElement.style.opacity = "1";
              }
              word.style.background =
                index == currentIndex || wordsFiltered.length == 0
                  ? "#FFBB00"
                  : "#FFFF00";
              word.style.color = "black";
            }
          });

                    handleSearchScroll(currentIndex, wordsFiltered)
                    
                    setHighlightWords(wordsFiltered)
                } else {
                    setHighlightWords(undefined)
                    setCurrentIndexSearch(-1);
                }
            }
        }
    };

    useEffect(() => {
        if(hideSearch) {
            onCloseHighlights()
        } else {
      if (
        searchBoxComponentRef.current &&
        searchBoxComponentRef.current.hasFocus() == false
      ) {
                searchBoxComponentRef.current.focus();
            }
        }
    }, [hideSearch]);

    const onCloseHighlights = () => {
        const textPdf: HTMLCollection | Element[] | undefined = textPdfList.reduce(
        (prev, curr) => {
        return Array.from(prev!).concat(Array.from(curr!));
      },
      []
    );


        if(textPdf){
            Array.from(textPdf).forEach(span => {
                if(span instanceof HTMLSpanElement){
                    const fatherElement = span.parentElement;
                    if(fatherElement){
                        fatherElement.style.opacity = "0.2" 
                    }
                    span.style.background = "none"
                    span.style.color = "transparent"
                }
            });
        }

        setHighlightWords(undefined)
        setCurrentIndexSearch(-1);
    }

    function ComboboxChange(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string){
        if(option?.key != undefined){
            setScale(Number(option.key))
        }
    }

    const handleScroll = (ref: Element) => {
        if(ref instanceof HTMLSpanElement || ref instanceof HTMLDivElement){
            if(scrollViewerRef.current){
                const scrollViewer = scrollViewerRef.current;
                const difference = ref.getBoundingClientRect().top - scrollViewer.getBoundingClientRect().top;

                const scrollToMiddle = scrollViewer.scrollTop + difference - (scrollViewer.clientHeight - ref.clientHeight) / 2;
             
                scrollViewer.scrollTo({
                  top: scrollToMiddle,
                  behavior: 'smooth'
                });
            }
        }
    };

    const handleSearchScroll = (newIndex: number, wordlist?: Element[]) => {
        if(wordlist){
            const oldHighlightWord = wordlist[currentSearchIndex]
            if(oldHighlightWord instanceof HTMLSpanElement){
                oldHighlightWord.style.background = "#FFFF00"
            }

            const highlightWord = wordlist[newIndex];
            if(highlightWord instanceof HTMLSpanElement){
                highlightWord.style.background = "#FFBB00"
            }
            handleScroll(highlightWord); 
            setCurrentIndexSearch(newIndex)
        }
    }

    useEffect(() => {
        setPdfPageList([])
        setLoader(true)

        PdfJs.getDocument(
            props.url,
        ).promise.then(pdf => {
            setPdfDocument(pdf)

            for (let numberPage = 1; numberPage <= pdf.numPages; numberPage++) {
                pdf.getPage(numberPage).then(page => {
                    if(numberPage == 1){
                        updateFirstPageSize(page)
                    }
                    setPdfPageList(prev => [...prev, page])
                }).catch(error => {
                    if(props.activateSnackbar) props.activateSnackbar(error.message);
                })
            }
        })
        .catch(error => {
            if(props.activateSnackbar) props.activateSnackbar(error.message);
        })
        .finally(() => setLoader(false));
    }, [props.url])


    const fetchPage = (pageNumber : number) => {
        const alreadyExist = canRenderPageList.includes(pageNumber);
        if(!alreadyExist){
            setCanRenderPageList(prev => [...prev, pageNumber])
        }
    }

    useEffect(() => {
        if (searchBoxComponentRef.current && searchBoxComponentRef.current.hasFocus() == false) {
            searchBoxComponentRef.current.focus();
        }
    }, [searchBoxRef, searchBoxComponentRef]);

    const openSearchHighlights = () => {
        toggleHideSearch()
    }

    const updateFirstPageSize = (page: PdfJs.PDFPageProxy) => {
        const { width, height } = page.getViewport({ scale });
        setDocumentSize({
            width,
            height
        })
    }

    function setHeightDependingByPage(): string {
        if (props.isDetailPage) {
            return "calc(100% - 300px)";
        }

        if (props.isFilePage) {
            return "calc(100% - 144px)";
        }

        if (props.showList) {
            return "calc(93% - "+ props.heightViewer.toString() +"px)"
        }

        return "93%"
    }

    function setWidthDependsPage(): string 
    {
        if (!props.fullScreenSetted && props.isSidebarCollapsed)
            return "92.5%"

        return "100%"
    }

    const onChangeHighlightsHandle = (newValue: string | undefined) => {
        if(!inputHighlightHasBeenChanged) setInputHighlightHasBeenChanged(true)
        setSearchText(newValue)
    } 

    const onClickSearchHighlightHandle = (newIndex: number, wordlist?: Element[]) => {
        if(inputHighlightHasBeenChanged){
            onSearchHighlights()
            setInputHighlightHasBeenChanged(false)
        } else {
            handleSearchScroll(newIndex, wordlist)
        }
    }

    return (
        <div>          
            {loader || pdfDocument == undefined ? 
                (
                    <div className={contentStyles.loader}>
                        <Loader size={120} className={contentStyles.loaderSave}/>
                    </div>
                ) : (
                    <div style={{ height: `${props.fullScreenSetted ? "100%" : "90%"}` }}>
                        <HeaderViewerComp
                            fullScreenSetted={props.fullScreenSetted}
                            isSidebarCollapsed={props.isSidebarCollapsed}
                            haveChoicerViwerChangeList={props.changeModalContent != undefined}
                            title={props.title}
                            showList={props.showList}
                            isAcept={props.isAcept}
                            menuConfig={props.menuConfig}
                            hideSidebar={props.hideSidebar}
                            toggleHideSidebar={props.toggleHideSidebar}
                            toggleHideDialog={props.toggleHideDialog}
                            cancel={props.cancel}
                            isPdfViewer={true}
                            openFileSetting={props.openFileSetting}
                            printControls={
                                props.openPdfWindowPrint == undefined ?
                                    undefined :
                                    <div className={styles.displayFlex}>
                                        <button className={styles.buttonIconPrint}
                                            onClick={() => props.openPdfWindowPrint && props.openPdfWindowPrint('pdf')}
                                        >
                                            <PrintIcon className={styles.iconSize} />
                                        </button>
                                    </div>
                            }
                            pageControls={
                                <div className={styles.displayFlex}>
                                    <button className={styles.buttonIconHeader} style={{transform: "rotate(270deg)"}} 
                                        disabled={page === pdfDocument.numPages}
                                        onClick={() => setPage!(page! + 1)}
                                    >
                                        <ArrowIcon className={styles.iconSize}/>
                                    </button>
                                    <button className={styles.buttonIconHeader} style={{transform: "rotate(90deg)" }}
                                        disabled={page === 1} 
                                        onClick={() => setPage!(page! - 1)}
                                    >
                                        <ArrowIcon className={styles.iconSize}/>
                                    </button>
                                    <div className={`${styles.displayFlex} ${styles.gapSpan}`}>
                                        <span className={styles.spanPage}>{page < 10 ? `0${page}` : page}</span>
                                        <span>de</span>
                                        <span>{pdfDocument.numPages < 10 ? `0${pdfDocument.numPages}` : pdfDocument.numPages}</span>
                                    </div>
                                </div>
                            }
                            scaleControls={
                                <div className={styles.displayFlex}>
                                    <button className={styles.buttonIconHeader}
                                        onClick={() => setScale(scale + 0.5)}
                                        disabled={scale === 5}
                                    >
                                        <MoreIcon className={styles.iconSize}/>
                                    </button>
                                    <button className={styles.buttonIconHeader}
                                        onClick={() => setScale(scale - 0.5)}
                                        disabled={scale === 0.5}  
                                    >
                                        <LessIcon className={styles.iconSize}/>
                                    </button>
                                    <ComboBox
                                        componentRef={comboBoxRef}
                                        options={options}
                                        styles={comboBoxStyles}
                                        selectedKey={keySelect}
                                        onItemClick={ComboboxChange}
                                        allowFreeform={false}
                                    /> 

                                </div>
                            }
                            highlightControls={
                                <div className={styles.displayFlex}>
                                    <button 
                                        ref={refButton!} 
                                        onBlur={e => {
                                            if (hideSearch)
                                            {
                                                e.currentTarget.focus();
                                            } 
                                        }}
                                        className={!hideSearch ? `${styles.buttonIconActive} ${styles.buttonIconHeader}` : styles.buttonIconHeader} 
                                        onClick={openSearchHighlights}
                                    >
                                        <SearchIcon className={styles.iconSize}/>
                                    </button>
                                    {!hideSearch && (
                                        <div className={styles.search} style={{top: props.showList ? "23%" : props.isAcept ? "calc(21% - 16px)" : "calc(21% - 96px)"}}>
                                            <SearchBox placeholder={t("WhatAreYouLookingFor.message")} 
                                                className={styles.inputSearch} 
                                                value={searchText} 
                                                onChange={(_, newValue) => onChangeHighlightsHandle(newValue)}
                                                styles={searchBoxStyles}
                                                onSearch={onSearchHighlights}
                                                onClear={onCloseHighlights}
                                                componentRef={searchBoxComponentRef}
                                                ref={searchBoxRef}
                                                onKeyDown={e => {
                                                    if (e.key === "f" && e.ctrlKey || e.keyCode === 114)
                                                    {
                                                        e.preventDefault();
                                                        toggleHideSearch();
                                                        refButton.current?.focus();
                                                    }
                                                }}
                                                onBlur={e => {
                                                    refButton.current?.focus();
                                                }}
                                            />
                                            <button className={styles.buttonIconHeader} style={{transform: "rotate(270deg)"}} 
                                                disabled={currentSearchIndex + 1 === highlightWords?.length}
                                                onClick={
                                                    () => {
                                                        onClickSearchHighlightHandle(currentSearchIndex + 1, highlightWords);
                                                        searchBoxComponentRef.current?.focus();
                                                    }
                                                }
                                            >
                                                <ArrowIcon className={styles.iconSize}/>
                                            </button>
                                            <button className={styles.buttonIconHeader} style={{transform: "rotate(90deg)" }}
                                                disabled={currentSearchIndex === 0} 
                                                onClick={
                                                    () => 
                                                    {
                                                        onClickSearchHighlightHandle(currentSearchIndex - 1, highlightWords);
                                                        searchBoxComponentRef.current?.focus();
                                                    }
                                                }
                                            >
                                                <ArrowIcon className={styles.iconSize}/>
                                            </button>
                                            <div className={`${styles.displayFlex} ${styles.gapSpan}`}>
                                                <span className={styles.spanPage}>{currentSearchIndex + 1 < 10 ? `0${currentSearchIndex + 1}` : currentSearchIndex + 1}</span>
                                                <span>de</span>
                                                <span>{highlightWords?.length ? highlightWords?.length < 10 ? `0${highlightWords?.length}` : highlightWords?.length : "00"}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        />

                        <div ref={canvasMainRef} style={props.positionabsolute == true?{
                            position: "absolute",
                            display:"flex",
                            flexDirection: "row-reverse",
                            width:setWidthDependsPage(),
                            height: setHeightDependingByPage()
                        }:undefined}>
                            <div className={contentStyles.canvaStyle} ref={scrollViewerRef} >
                                <div className={contentStyles.canvasList}>
                                    {documentSize && (
                                        Array.from({ length: pdfDocument.numPages }, (_, index) => (
                                            <LazyViewer 
                                                key={index}
                                                canRender={canRenderPageList.some(number => number == index + 1)}
                                                documentSize={documentSize}
                                                fetchPage={fetchPage}
                                                pageNumber={index + 1}
                                                pdfPage={pdfPageList.find(pdfPage => pdfPage.pageNumber == index + 1)}
                                                scale={scale}
                                                setTextPdfList={setTextPdfList}
                                                ref={(element) => canvasRefList.current.push(element)}
                                            />
                                        ))
                                    )}
                                </div>
                            </div>
                                <SidebarViewerComp 
                                    children={
                                        pdfDocument && (
                                            Array.from({ length: pdfDocument.numPages }, (_, index) => (
                                                <LazyPreview 
                                                    key={index}
                                                    canRender={canRenderPageList.some(number => number == index + 1)}
                                                    fetchPage={fetchPage}
                                                    pdfPage={pdfPageList.find(pdfPage => pdfPage.pageNumber == index + 1)}
                                                    pageNumber={index + 1}
                                                    setPageScroll={setPage}
                                                />
                                            ))
                                        )
                                    }
                                    hideSidebar={props.hideSidebar}
                                    isAcept={props.isAcept}
                                    changeModalContent={props.changeModalContent}
                                />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

const contentStyles = mergeStyleSets({
    container: {
        width:"381.276px !important",
        height:"503.89px !important"
    },
    btnLeft:{
        float:"left",
        border:"none",
        backgroundColor:"white",
        cursor:"pointer",
        ':hover':{
            background: "lightgray"
        }
    },
    btnRigth:{
        float:"left",
        border:"none",
        backgroundColor:"white",
        cursor:"pointer",
        ':hover':{
            background: "lightgray"
        },
        marginRight: "3%"
    },
    btnZoom:{
        marginLeft:"5px",
        border: "none",
        backgroundColor: "white",
        cursor: "pointer",
        ':hover':{
            background: "lightgray"
        },
    },
    btnImpressao:{
        float:"left",
        border:"none",
        backgroundColor:"white",
        cursor:"pointer",
        ':hover':{
            background: "lightgray"
        },
        marginLeft: "2%"
    },
    inputPage:{
        width: "8%"
    },
    totalPage:{
        marginLeft: "1%",
    },
    canvaStyle:{
        textAlignLast: "end",
        background: "#e1e1e1",
        padding: "9% !important",
        flexGrow: "1",
        overflowY: "auto",
    },
    canvasList:{        
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        alignItems: "center",
        justifyContent: "center",
    },
    header:{
        background:"white", 
        textAlign:"center",
        display:"flex",
        placeContent:"center", 
    },
    headerRoot:{
        display: "grid", 
        gridTemplateColumns: "10fr 1fr 1fr"
    },
    headerButtons:{
        position: "relative", 
        padding: "8px 0", 
        alignSelf: "center"
    },
    headerPagenation:{
        display: "flex", 
        justifyContent: "space-around"
    },
    searchDiv:{
        display: 'flex',
        justifyContent: 'center',
    },
    loader:{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: "#fff"
    },
    loaderSave: {
        justifyContent:"left",
        width:"50px",
        paddingTop:"10px",
        paddingBottom:"10px"
    },
})


export default PDFView;
