import React, { useState, useEffect } from "react";
import { initializeIcons, TextField } from "@fluentui/react";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { useProfile } from "../../../context/ProfileProvider/useProfile";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { LoginRequest, LoginRequestMS } from "../../../context/AuthProvider/util";
import { IUser } from "../../../context/AuthProvider/types";
import { useBoolean } from "@fluentui/react-hooks";
import { RedirectNavigate } from "../../PublicLayout/RedirectNavigate";
import {LeftForm} from "../../PublicLayout/components/Handle/LeftForm";
import { Grid, Box, Typography, Link } from '@mui/material';
import FormButton from "../../PublicLayout/components/Handle/Form/Root/components/FormButton/FormButton";
import FormActions from "../../PublicLayout/components/Handle/Form/Root/components/FormActions/FormAction";
import FormErrors from "../../PublicLayout/components/Handle/Form/Root/components/FormErrors/FormErrors";
import { GetMfaInformation } from "../../../services/UserServices";
import msalConfig from "../../../config/office-auth.config.json";
import { useMsal } from "@azure/msal-react";
import { ButtonTyppe } from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import pasteIcon from '../../../assets/icons/microsoftLogo.svg';
import { MfaAuthVO } from "../../../model/UserModel";

initializeIcons();

const LoginComp = (props: any) => {
  const [isChecked, setCheckedState] = useState<boolean>(false);
  const { t } = useTranslation();
  const navegate = useNavigate();
  const auth = useAuth();
  const profile = useProfile();
  const [load, setLoad] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [messagePass, setMessagePass] = useState<string>("");
  const { instance } = useMsal();

  const onChangeEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setEmail(newValue);
    } else {
      setEmail("");
    }
  };


  const onChangePassword = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      setPassword(newValue);
    } else {
      setPassword("");
    }
  };

  function loginOffice() {

    setCheckedState(false);
    setLoad(true);

    instance.loginPopup({
      scopes: [msalConfig.scopes["User.Read"]],
      authority: msalConfig.auth.authority,
      redirectUri: window.location.origin
    })
    .then(response => {
      if(response.idToken != undefined)
      {
        LoginRequestMS(response.idToken)
        .then(message => {
          setProfile(message.data.access_token);
        })
        .catch((error) => {
          erroManagement(error)
        });
      }
      else
      {
        SetErrorLogin();
      }
    })
    .catch((error) => {
      erroManagement(error)
    });
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoad(true);
    LoginRequest(email, password)
      .then((message) => {
          setProfile(message.data.access_token) // Usuário retornado com 200, manda direto para aplicação.
      })
      .catch((error) => {
        erroManagement(error)
      });
  };

  function setProfile(token:string)
  {
    profile
      .create(token)
      .then(() => {
        setLoad(false);
        let user: IUser = {
          token:token
        };
        auth.setUserExternal(user);
        RedirectNavigate(navegate)
      })
      .catch((error) => {
        auth.logout();
        setCheckedState(true);
        setLoad(false);
      });
  }

  function erroManagement(error: any)
  {
    if (
      error.response != null &&
      error.response.data != null &&
      error.response.data.error == "invalid_client"
    ) {
      SetErrorLogin();
    }
    else if (
      error.response != null &&
      error.response.data != null &&
      error.response.data.error == "unauthorized_client"
    ) {
      // Ambos erros passados para cliente não autorizado e não gerando token (retorno de 400 bad request).
      if (error.response.data.user.Key != undefined) { // Verificação caso gerado nova chave mfa no backend.
        navegate(`/login/qrcode/${error.response.data.user.Login}/${error.response.data.user.Key}`); // Se sim, deve enviar para ler qrcode novamente.
      } else {
        navegate(`/login/otp/${error.response.data.user.Login}`); // Se não, manda para usuário colocar senha OTP gerado nop autenticador.
      }
    } else {
      SetErrorLogin();
    }
  }

  function SetErrorLogin()
  {
      setLoad(false);
      setCheckedState(true);
  }

  function send() {
    navegate("/twoFaComp");
  }

  return (
    <LeftForm
      formRootInstance={{
        title:t("Login.accessAcount"),
        subTitle:t("Login.helloAgain")
      }}
    >
      <Grid container sx={{
        gap: 4
      }}>
        <Grid item xs={12}>
            <Typography
              component='label'
              sx={{
                textAlign: "left",
                font: "normal normal 600 16px/24px Segoe UI",
                letterSpacing: "0px",
                color: "#2D2D2D",
                opacity: 1,
              }}
            >
              E-mail
            </Typography>
            <TextField 
              onChange={onChangeEmail}
              value={email}
              styles={{
                fieldGroup: {
                  background: "#FBFBFB",
                  border: "2px solid #2D2D2D4D",
                  borderRadius: "8px",
                  opacity: 1,
                  height: '54px'
                }
              }}
              onFocus={() => {
                if (isChecked)
                { 
                  setCheckedState(false);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Typography
                component='label'
                sx={{
                  textAlign: "left",
                  font: "normal normal 600 16px/24px Segoe UI",
                  letterSpacing: "0px",
                  color: "#2D2D2D",
                  opacity: 1,
                }}
              >
              {t("Login.password")}
            </Typography>
            <Link
              sx={{
                textAlign: "left",
                font: "normal normal 600 16px/24px Segoe UI",
                letterSpacing: "0px",
                color: "#005A9E",
                opacity: 1,
              }}
              href="/resetPassword"
            >
              {t("Login.forgotPass")}
            </Link>
            </Box>
            <TextField 
              onChange={onChangePassword}
              value={password}
              styles={{
                fieldGroup: {
                  background: "#FBFBFB",
                  border: "2px solid #2D2D2D4D",
                  borderRadius: "8px",
                  opacity: 1,
                  height: '54px'
                }
              }}
              type='password'
              onFocus={() => {
                if (isChecked) 
                {
                  setCheckedState(false)
                }
              }}
            />

          </Grid>
          <FormErrors 
            messagePass={t("UsuarioOuSenhaIncorretors.message")}
            isChecked={isChecked}
          />
          <FormButton 
            load={load}
            btnBigWithIconConfig={{
              text:t("Entrar.message"),
              onClick: (e: any) => handleSubmit(e)
            }}
          />
          <FormButton 
            load={load}
            btnBigWithIconConfig={{
              text:t("Microsoft"),
              isPrimary:ButtonTyppe.secondary,
              src:pasteIcon,
              onClick: (e: any) => {
                loginOffice()
              }
            }}
          />
          <FormActions 
            contextText={t("Login.alreadyhasCode")}
            text={t("Login.goToEmailPage")}
            href="/twoFaComp"
          />
      </Grid>
    </LeftForm>
  );
};

export default LoginComp;
