import { ParameterViwer } from "../pages/WorkSpaceItem/Viwer/ParameterViwer";

export function ReturnParameterViwer (parameter:ParameterViwer | undefined, initial: boolean) {
    if(parameter == undefined || initial == undefined){
        return""
    }

    let firstCharactere:string = initial ? "?" : "&";

     if(parameter.converterType == 1){
      if(parameter.positionXZoomPng != undefined && parameter.positionYZoomPng  != undefined){
        prt = firstCharactere + "converterType=" + parameter.converterType + "&backgroundColors=" + parameter.backgroundColors + 
        "&zoom=" + parameter.zoom + "&positionXZoomPng="+parameter.positionXZoomPng + "&positionYZoomPng=" + parameter.positionYZoomPng
      }
      else{
        prt =firstCharactere + "?converterType=" + parameter.converterType + "&backgroundColors=" + parameter.backgroundColors + 
        "&zoom=" + parameter.zoom
      }

    }
    else{
        var prt = firstCharactere + "size=" + parameter.size + "&palette=" +parameter.palette +
        "&orientation=" + parameter.orientation + "&converterType=" + parameter.converterType
    }



    return prt;
}
