
import React, { ComponentType } from "react";
import { Tooltip, TooltipHost, mergeStyles } from "@fluentui/react";  
import {IconDisable} from "../../assets/icons/iconDisable/IconDisable"
import { Color } from "../../assets/icons/interface/Color";

export const containerStyles = mergeStyles({
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    textAlign: "left",
    font: "normal normal normal 14px/21px Segoe UI",
    letterSpacing: "0.13px",
    color: "#2D2D2D",
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '8px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: "#f5f5f5"
    }
  });

  const itemStyle = mergeStyles({
    marginRight: '10px',
    width: '20px'
  })

  export interface MenuStiledRenderProps {
    item:any, 
    widthStandar:boolean,
    disabled: boolean,
    src?: string | undefined, 
    elementIcon?: { icon: React.FC<Color>, color: string},
    onClick?: React.MouseEventHandler | undefined,
    icon?: ComponentType<IconDisable>
    disableStyles?: boolean;
    tooltipText?: string;
    text?: string;
  }

  const MenuStiledRender = (props: MenuStiledRenderProps) => {

    return (
        <div 
            className={!props.disableStyles ? `${containerStyles}` : ``} 
            onClick={props.disabled == true ?undefined: props.onClick }
            style={{
                color: props.disabled == false ? undefined: "#bababa"
            }}
        >
          {props.icon != undefined && (
            <div className={itemStyle}>
              <props.icon
                disable = {props.disabled}
              />
            </div>
          )}
          {props.src != undefined && (
            <div className={itemStyle}>
              <img 
                src={props.src} 
                width={props.widthStandar?20:16} 
                style={
                  props.widthStandar?
                    undefined :
                    {
                      marginLeft:"4px",
                      marginRight:"4px"
                    }
                }
              />
            </div>
          )}
          <TooltipHost
            content={props.tooltipText}
          >
            <div style={{display:"flex"}}>
              {props.elementIcon != undefined && (
                <div className={itemStyle}>
                  <props.elementIcon.icon color={props.disabled == false ? props.elementIcon.color: "#bababa"}/>
                </div>
              )}
              {props.item.text} 
            </div>
          </TooltipHost>
        </div>
      )
}

export default MenuStiledRender;
