
interface BaseSvgProps {
    color: any
    width: string
    height: string
    opacity?: string
}

export function BaseSvg({ color, width, height, opacity }: BaseSvgProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20">
            <g id="schedule" transform="translate(-2 -2)" opacity={opacity}>
                <path id="Caminho_58" data-name="Caminho 58" d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" fill={color}/>
                <path id="Caminho_59" data-name="Caminho 59" d="M12.5,7H11v6l5.25,3.15L17,14.92l-4.5-2.67Z" fill={color}/>
            </g>
        </svg>
    )
}