import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import Btn, { TypeStyle } from "../../../../components/Button/Btn"
import { useTranslation } from "react-i18next";
import { putMfaKey } from "../../../../services/IdentitiServices";
import { DialogComponent } from "../../../../components/DialogComponent";

export interface IOtpDialog {
    openDialog: boolean;
    handleOpenDialog: (set: boolean) => void;
    handleMfaKey: () => void;
    email: string;
}

export const OtpDialog: React.FC<IOtpDialog> = ({ handleOpenDialog, openDialog, email, handleMfaKey }) => {

    const { t } = useTranslation();

    return (
        <DialogComponent
            isOpen={openDialog}
            onClickCancel={handleOpenDialog}
            onClickConfirm={handleMfaKey}
            titleContent={t("otpDialog.title")}
            isOtpDialog
            isCancelButtonNeeded
        >
            <Typography
                component='label'
                sx={{
                    textAlign: "left",
                    font: "normal normal 400 18px/28px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    marginBottom: '10px'
                }}
            >
                {t("otpDialog.contentEmail")} { email }
            </Typography>

            <Typography
                component='label'
                sx={{
                    textAlign: "left",
                    font: "normal normal 300 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    marginTop: '10px'
                }}
            >
                {t("otpDialog.content")}
            </Typography>
        </DialogComponent>
    )
}