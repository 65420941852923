import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {LeftForm} from "../../PublicLayout/components/Handle/LeftForm";
import { useEffect, useState } from 'react';
import { GetMfaInformation } from '../../../services/UserServices';
import { QRCodeSVG } from 'qrcode.react'; 
import { Box, Snackbar, Typography } from '@mui/material';
import { MfaAuthVO } from '../../../model/UserModel';
import FormButton from '../../PublicLayout/components/Handle/Form/Root/components/FormButton/FormButton';
import MuiAlert, { AlertColor } from '@mui/material/Alert';

const MfaQRCode = () => {

    const { email, key } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    

    function setMfaUrl() {
        let issuer = "w3k";

        return `otpauth://totp/${issuer}:${email}?secret=${key}&issuer=${issuer}`;
    }

  
    return (
        <>
            <LeftForm
                formRootInstance={{
                    title:t("Login.qrcodeTitle"),
                    subTitle:t("Login.qrcodeSub")
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "16px"
                    }}
                >
                    <QRCodeSVG value={setMfaUrl()} size={200} />
                </Box>
                <Box>
                    <Typography
                        sx={{
                            textAlign: "left",
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 1,
                            padding: "16px"
                        }}
                    >
                        {t("Login.qrcodeRedirect")}
                    </Typography>
                </Box>
                <FormButton
                    load={false}
                    btnBigWithIconConfig={{
                    text:t("Login.returnToLoginPage"),
                    onClick:(e: any) => navigate(`/login/otp/${email}`, { replace: true })
                    }}
                />
            </LeftForm>
        </>
    );
}

export default MfaQRCode;