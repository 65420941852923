export interface ZipConfig {}

export const Zip: React.FC<ZipConfig> = ({}) => {
  return(
	<svg version="1.1" id="Camada_2_00000039127297054938278630000011511440548856758705_"
		xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 39.8 51.9"
		style={{ background: "new 0 0 39.8 51.9" }} xmlSpace="preserve">
	<style type="text/css">
		{`
			.zip0{fill-rule:evenodd;clip-rule:evenodd;fill:#EFC100;}
			.zip1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFE448;}
			.zip2{fill:#FFFFFF;}
		`}
	</style>
	<g>
		<path id="Caminho_136" className="zip0" d="M39.8,13.7v34.2c0,2.3-1.8,4.1-4.1,4.1H4.1C1.8,52,0,50.2,0,47.9l0,0V4.1C0,1.8,1.9,0,4.1,0
			H26C26.1,0,39.8,13.7,39.8,13.7z"/>
		<path id="Caminho_138" className="zip1" d="M39.8,12.9v1.7H28.5c-2.3,0-3.4-1.9-3.4-4.2V0h1.7C26.9,0,39.8,12.9,39.8,12.9z"/>
		<g>
			<path className="zip2" d="M17.4,28.9l-3.2,4.4h3.2v1.1h-5.1v-0.5l3.3-4.4h-3v-1.1h4.8V28.9z"/>
			<path className="zip2" d="M19.2,27.1c-0.2,0-0.4-0.1-0.6-0.2s-0.2-0.3-0.2-0.6s0.1-0.4,0.2-0.6s0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
				s0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.5S19.5,27.1,19.2,27.1z M19.9,34.3h-1.4v-6h1.4V34.3z"/>
			<path className="zip2" d="M23.1,33.4L23.1,33.4l0,3.6h-1.4v-8.8H23v1.1h0c0.5-0.8,1.1-1.2,2-1.2c0.8,0,1.4,0.3,1.8,0.8s0.6,1.3,0.6,2.2
				c0,1-0.2,1.8-0.7,2.4s-1.1,0.9-2,0.9C24.1,34.4,23.5,34.1,23.1,33.4z M23,31.1v0.7c0,0.4,0.1,0.8,0.4,1.1s0.6,0.5,1.1,0.5
				c0.5,0,0.9-0.2,1.2-0.6s0.4-0.9,0.4-1.6c0-0.6-0.1-1.1-0.4-1.4s-0.6-0.5-1.1-0.5c-0.5,0-0.9,0.2-1.2,0.5S23,30.6,23,31.1z"/>
		</g>
	</g>
	</svg>
  )
}

