export interface WebpConfig {}

export const Webp: React.FC<WebpConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .webp-1 {
              fill: #fff;
            }

            .webp-2 {
              fill: none;
              isolation: isolate;
            }

            .webp-3 {
              fill: #b3b3b3;
            }

            .webp-3, .webp-4 {
              fill-rule: evenodd;
            }

            .webp-4 {
              fill: #d6d6d6;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_755" data-name="Grupo 755">
        <g id="Grupo_740" data-name="Grupo 740">
          <path id="Caminho_136" data-name="Caminho 136" className="webp-3" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_138" data-name="Caminho 138" className="webp-4" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="wepb" className="webp-2">
          <g className="webp-2">
            <path className="webp-1" d="m13.45,27.97l-2.06,7h-1.66l-1.27-4.74c-.05-.18-.08-.39-.1-.62h-.03c0,.16-.05.36-.12.6l-1.38,4.76h-1.63l-2.06-7h1.64l1.27,5.04c.04.15.07.35.08.6h.05c.01-.19.05-.4.11-.62l1.42-5.03h1.49l1.26,5.07c.04.16.07.36.09.6h.05c0-.17.04-.37.1-.6l1.24-5.07h1.51Z"/>
            <path className="webp-1" d="m20.48,31.9h-4.77c.02.65.22,1.15.6,1.5s.9.53,1.57.53c.75,0,1.43-.22,2.06-.67v1.28c-.64.4-1.48.6-2.53.6s-1.84-.32-2.42-.95c-.59-.64-.88-1.53-.88-2.68,0-1.09.32-1.98.97-2.66.65-.69,1.45-1.03,2.4-1.03s1.7.31,2.22.92.79,1.47.79,2.56v.61Zm-1.53-1.12c0-.57-.14-1.01-.4-1.33-.26-.32-.63-.48-1.09-.48s-.84.17-1.16.5-.51.77-.58,1.31h3.24Z"/>
            <path className="webp-1" d="m23.6,33.94h-.03v4.25h-1.59v-10.22h1.59v1.23h.03c.54-.93,1.34-1.39,2.38-1.39.89,0,1.58.31,2.08.94s.75,1.46.75,2.52c0,1.17-.28,2.1-.84,2.8s-1.33,1.05-2.3,1.05c-.89,0-1.58-.39-2.06-1.18Zm-.04-2.76v.87c0,.51.16.94.49,1.3.33.36.74.53,1.24.53.59,0,1.05-.23,1.38-.69.34-.46.5-1.1.5-1.92,0-.69-.16-1.23-.47-1.62-.31-.39-.74-.58-1.27-.58-.57,0-1.02.2-1.36.6-.34.4-.52.9-.52,1.5Z"/>
            <path className="webp-1" d="m32.04,33.96h-.03v1.01h-1.59v-10.36h1.59v4.59h.03c.54-.93,1.34-1.39,2.38-1.39.88,0,1.58.31,2.07.94.5.63.75,1.47.75,2.52,0,1.17-.28,2.11-.84,2.81-.56.71-1.33,1.06-2.3,1.06-.91,0-1.6-.39-2.06-1.18Zm-.04-2.78v.87c0,.51.16.94.49,1.3.33.36.74.53,1.24.53.59,0,1.05-.23,1.38-.69.34-.46.5-1.1.5-1.92,0-.69-.16-1.23-.47-1.62-.31-.39-.74-.58-1.27-.58-.57,0-1.02.2-1.36.6-.34.4-.52.9-.52,1.5Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
