import { ItemValidationRule , WorkSpaceUserName} from "../../../../../model/ItemModel";
import { IContextProfile } from "../../../../../model/UserModel";
import substituir from '../../../../../assets/icons/substituir.svg';
import styles from "../../../WorkSpaceItemList.module.css";
import { ReplaceUpload } from "../../../UploadFunction";
import { useUpload } from "../../../../../context/UploadProvider/useUpload";

interface IReplaceFile {
    itemType: string;
    permissionType: number;
    validationRuleConst: ItemValidationRule | undefined;
    isReleaseConst?: boolean;
    profile: IContextProfile;
    currentUserDraft: string | undefined;
    idFile: string;
    idMongo: string | undefined;
    isUpload: boolean;
    workspaceItem: WorkSpaceUserName
}

export function ReplaceFile({ 
    itemType, 
    permissionType, 
    isReleaseConst, 
    validationRuleConst, 
    profile, 
    currentUserDraft, 
    idFile,
    idMongo,
    isUpload,
    workspaceItem
}: IReplaceFile) {

    const upload = useUpload();

    function getUpload() {
        return upload;
    }

    const onChangeFileChooser = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files: FileList | null = e.target.files;
        if (files !== null && files.length == 1) {
            ReplaceUpload(files[0], idMongo!, getUpload);
        }
        upload.setIsReplace(true);
    };

    return (
        (itemType === "file" && 
        (permissionType === 1 || permissionType === 3) &&
        (validationRuleConst === undefined || (validationRuleConst !== undefined && isReleaseConst === true)) && 
        (profile.id === currentUserDraft && isReleaseConst === false)
        ) ? (
            <>
                <input
                    id={idFile}
                    type="file"
                    onChange={(e) => onChangeFileChooser(e)}
                    className={styles.uploadInput}
                />
                <img 
                    src={substituir} 
                    className={styles.imgSubstituir} 
                    style={{color:"#114D88"}}  
                    onClick={() => {  
                        document.getElementById(idFile)!.click();
                    }}
                />
            </>
        ) : null
    )
}