import { useEffect, useState } from 'react'
import { ViewerOptions } from '../../../../pages/WorkSpaceItem/Viwer/ChoicerViwer';
import { fileStylesSet, noViwerStylesSet, pdfStylesSet } from '../../stylehandlers/stylesHandlers';

export interface IuseStyledModal {
    viwerOptions: ViewerOptions;
    isSidebarCollapsed: boolean;
    fullScreenSetted: boolean;
    isModalViwer: boolean;
}

export const useStyledModal = (viwerOptions: ViewerOptions, isSidebarCollapsed: boolean, fullScreenSetted: boolean, isAcept: boolean, isModalViwer: boolean) => {
    const [viwerPdfStyles, setViwerPdfStyles] = useState<{ width: string, height: string }>({ height: "725px", width: "69.5%" });
    const [noViwerStyles, setNoViwerStyles] = useState<{ width: string, height: string }>({ height: "49.6%", width: "69.5%" });
    const [fileViwerStyles, setFileViwerStyles] = useState<{ width: string, height: string }>({ height: "47.8%", width: "63.9%" });

    useEffect(() => {
        viwerOptionsSetter(viwerOptions)
    }, [viwerOptions, isSidebarCollapsed, fullScreenSetted])

    function viwerOptionsSetter(viwerOptions: ViewerOptions) {
        switch(viwerOptions) {
            case "Pdf":
                setViwerPdfStyles(pdfStylesSet(isSidebarCollapsed, fullScreenSetted, isAcept, isModalViwer))
                break;
            case "Image":
                setFileViwerStyles(fileStylesSet(isSidebarCollapsed, fullScreenSetted, isAcept, isModalViwer))
                break;
            case "NoViewer":
                setNoViwerStyles(noViwerStylesSet(isSidebarCollapsed, fullScreenSetted, isAcept, isModalViwer))
                break;
        }
    }

    return { viwerPdfStyles, noViwerStyles, fileViwerStyles };

}