import { Typography, Box } from '@mui/material';
import successIcon from '../../assets/icons/validacao-verde.svg'
import errorIcon from '../../assets/icons/warning-vermelho.svg'

export interface AlertProps {
  text: string;
  type: 'success' | 'error' | 'warning';
}

export const colors = {
  success: "#2D2D2D",
  error: '#D11422',
  warning: "",
}

export const icons = {
  success: successIcon,
  error: errorIcon,
  warning: ""
}

export default function AlertComponent({ text, type }: AlertProps) {
  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      gap: 2
    }}>
      <Box 
        component="img"
        src={icons[type]}
      />
      <Typography
        color={colors[type]}
        sx={{
          font: "normal normal 600 16px/24px Segoe UI",
          letterSpacing: "0px",
          opacity: 1,
          marginLeft: '10px'
        }}
      >
        { text }
      </Typography>
    </Box>
  )
}