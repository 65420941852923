import React, { ChangeEvent, useEffect, useState, useRef, useCallback } from "react";
import {
    WorkSpaceRevisionTrakker
  } from '../../../model/ItemModel';
import WorkSpaceItemListFileTrackerRevisionActivity from "./WorkSpaceItemListFileTrackerRevisionActivity"
import WorkSpaceItemListFileComponentImage from "../WorkSpaceItemListFileComponentImage";
import moment from 'moment';
import { useTranslation } from "react-i18next";

interface WorkSpaceItemListFileTrackerRevisionPops {
    workSpaceItemRevisionTrakker : WorkSpaceRevisionTrakker
}

const WorkSpaceItemListFileTrackerRevision: React.FC<WorkSpaceItemListFileTrackerRevisionPops> = ({
    workSpaceItemRevisionTrakker
}) => {

    const { t, i18n } = useTranslation();

    return (
        <div>

             {(workSpaceItemRevisionTrakker.trackerList != undefined && workSpaceItemRevisionTrakker.trackerList.length >0)&&(
                 workSpaceItemRevisionTrakker.trackerList?.map((tracker) => (
                    <WorkSpaceItemListFileTrackerRevisionActivity
                        key={tracker.id} 
                        trakkerList={tracker}
                        enumerate={workSpaceItemRevisionTrakker.enumerate!}
                    />
                 ))
             )}
        </div>
    )
}

export default WorkSpaceItemListFileTrackerRevision;