import styled from "styled-components"
import { IShowInfo } from "../../pages/DocumentDetail/DocumentDetail";
import { useStyledModal } from "./hooks/useStyledModal/useStyledModal";
import { ViewerOptions } from "../../pages/WorkSpaceItem/Viwer/ChoicerViwer";

export interface IModalStyled {
    children: React.ReactChild;
    isSidebarCollapsed: boolean;
    fullScreenSetted: boolean;
    isNoViwer?: boolean;
    showViwerInfo: IShowInfo;
    viewerOptions: ViewerOptions;
    isAcept: boolean;
    isModalViwer: boolean;
    isSharedFilePage: boolean;
}

export const ModalStyledByWidth = styled.div<{  $width?: string; $height?: string; $isModalViwer: boolean, $isSharedFilePage: boolean }>`
    padding: 0;
    width: ${props => props.$width};
    height: ${props => props.$isSharedFilePage ? "95vh" : props.$height};
    position: ${({ $isModalViwer }) => $isModalViwer ? 'absolute' : 'fixed'};
`;

export const ModalStyledNoViwerByWidth = styled.div<{  $width?: string; $height?: string; $showViwerInfo: IShowInfo, $isModalViwer: boolean, $isSharedFilePage: boolean }>`
    width: ${props => props.$width};
    height: ${props => props.$isSharedFilePage ? "95vh" : props.$height};;
    background: ${props => props.$showViwerInfo.value === '1' ? "#F2F3F7 0% 0% no-repeat padding-box" : ""};
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    position: ${({ $isModalViwer }) => $isModalViwer ? 'absolute' : 'fixed'};
`;



export const ModalStyled: React.FC<IModalStyled> = ({ children, fullScreenSetted, isSidebarCollapsed, isNoViwer, showViwerInfo, viewerOptions, isAcept, isModalViwer, isSharedFilePage }) => {

    const { fileViwerStyles, noViwerStyles, viwerPdfStyles } = useStyledModal(viewerOptions, isSidebarCollapsed, fullScreenSetted, isAcept, isModalViwer);

    if (viewerOptions == "Pdf") {
        return (
            <ModalStyledByWidth
                $height={viwerPdfStyles.height}
                $width={viwerPdfStyles.width}
                $isModalViwer={isModalViwer}
                $isSharedFilePage={isSharedFilePage}
            >
                { children }
            </ModalStyledByWidth>
        )
    }

    if (viewerOptions == "NoViewer") {
        return (
            <ModalStyledNoViwerByWidth
                $height={noViwerStyles.height}
                $width={noViwerStyles.width}
                $showViwerInfo={showViwerInfo!}                
                $isModalViwer={isModalViwer}
                $isSharedFilePage={isSharedFilePage}
            >
                { children }
            </ModalStyledNoViwerByWidth>
        )
    }

    return (
        <ModalStyledByWidth
            $height={fileViwerStyles.height}
            $width={fileViwerStyles.width}
            $isModalViwer={isModalViwer}
            $isSharedFilePage={isSharedFilePage}
        >
            {children}
        </ModalStyledByWidth>
    )
}