import { Box, Typography, Divider, Link } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function FormPrivacy() {

    const [year, setYear] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
        getYearHandler();
    }, [])

    function getYearHandler() {
        const newDate = new Date().getFullYear();
        setYear(newDate)    
    }

    return (
        <Box sx={{
            display: 'flex',
            gap: 4,
            justifyContent: 'center'
        }}>
            <Box>
                <Typography
                    sx={{
                        textAlign: "left",
                        font: "normal normal normal 18px/27px Segoe UI",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                        opacity: 0.4,
                    }}
                >
                © {year} {t("Login.allRights")}
                </Typography>
            </Box>
                <Divider orientation="vertical" flexItem />
            <Box>
                <Link
                    sx={{
                        textAlign: "left",
                        font: "normal normal normal 18px/27px Segoe UI",
                        letterSpacing: "0px",
                        color: "#2D2D2D",
                        opacity: 0.4,
                    }}
                    underline="none"
                    href="https://www.w3k.com.br/privacidade/"
                >
                    {t("Login.privacyPolicy")}
                </Link>
            </Box>
        </Box>
    )
}