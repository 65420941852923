import { Box, Link } from '@mui/material';
import blueArrow from '../../../../../../../../../assets/icons/arrow-left-blue.svg';

interface IFormActionLink {
    isRenderingArrow?: boolean;
    href?: string;
    text?: string;
}

export default function FormActionLink({
    isRenderingArrow,
    href,
    text
}: IFormActionLink)
{
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2
        }}>
            { isRenderingArrow && (
                <Box>
                    <img src={blueArrow} />
                </Box>
            ) }
            <Link
                underline='hover'
                sx={{
                    cursor: 'pointer',
                    textAlign: "center",
                    font: "normal normal 600 18px/27px Segoe UI",
                    letterSpacing: "0px",
                    color: "#005A9E",
                }}
                href={href}
            >
                { text }
            </Link>
        </Box>
    )
}