import React ,{ useState } from "react";
import { BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {UploadProvider} from '../context/UploadProvider/indexUpload';

import Home from "../pages/User/Home/HomeComp";
import Teste from "../pages/User/Home/TesteComp";
import Login from "../pages/User/Login/LoginComp";
import TwoFaComp from "../pages/User/TwoFa/TwoFaComp";
import ProtectedLayout from '../pages/ProtectedLayout/ILayoutProtected';
import PublicLayout from '../pages/PublicLayout/ILayoutPublic';
import ErrorPage from '../pages/ErroPage/ErrorPage';
import ServerDontAllowed from '../pages/ErroPage/ServerDontAllowed';
import OrganizationList from '../pages/Organization/OrganizationList';
import OrganizationConfig from '../pages/Organization/OrganizationConfig';
import ListUserOrg from '../pages/User/UserList/ListUserOrg';
import NewUserPassword from '../pages/User/NewPassword/NewUserPassword';
import GroupsList from '../pages/Groups/GroupsList';

import { useUpload } from "../context/UploadProvider/useUpload";
import WorkSpaceList from "../pages/WorkSpace/WorkSpaceList";
import WorkSpacePermissions from "../pages/WorkSpace/Permissions/WorkSpacePermissions";
import WorkSpacePermissionsUser from "../pages/WorkSpace/PermissionsUser/WorkSpacePermissionsUser";
import GroupUsers from "../pages/Groups/GroupUsers/GroupUsers";
import WorkSpaceAdmList from "../pages/WorkSpaceAdm/WorkSpaceAdmList"
import WorkSpaceItemMain from "../pages/WorkSpaceItem/WorkSpaceItemMain"
import ItemsPermissions from "../pages/Items/ItemPermission/ItemsPermissions"
import DocumentTypesList from "../pages/DocumentType/DocumentTypeList";
import DocumentTypeConfig from "../pages/DocumentType/DocumentTypeConfig";
import ValidationRuleList from "../pages/ValidationRule/ValidationRuleList/ValidationRuleList";
import ValidationRuleCreateEdit from "../pages/ValidationRule/ValidationRuleCreateEdit/ValidationRuleCreateEdit";
import UserPendenciesList from "../pages/UserPendencies/UserPendenciesList"
import PermissionGroupFolder from "../pages/WorkSpaceItem/PermissionFolder/PermissionGroup/PermissionGroupFolder"
import PermissionUserFolder from "../pages/WorkSpaceItem/PermissionFolder/PermissionUser/PermissionUserFolder"
import FeedList from "../pages/Feed/FeedList"
import Acept from "../pages/EmailAcept/Acept"
import ResetPassword from "../pages/User/ResetPassword/ResetPasswordComp";
import SharedFileAcess from "../pages/SharedFile/SharedFileAcess";
import ReturnBackEndParameters from "../pages/BackEndParameters/ReturnBackEndParameters";
import WizardFreeMain from "../pages/WizardFree/WizardFreeMain";
import EmailSendSuccess from '../pages/User/ResetPassword/EmailSendSuccess';
import MfaQRCode from "../pages/User/MfaQRCode/MfaQRCode";
import MfaOTP from "../pages/User/MfaOTP/MfaOTP";
import TemporalityList from '../pages/Temporality/Report/TemporalityList';
import TemporalityMenu from '../pages/Temporality/MenuWorkspace/TemporalityMenu';
import GridProvider from "../context/GridProvider/GridContext";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import LinkToFilePage from '../pages/LinkToFile/LinkToFilePage';

const AppRoutes = () => {

    const auth = useUpload();

    return(
        <UploadProvider>
            <GridProvider>
                <BrowserRouter>
                        <Routes>
                            <Route path="/acept" element = { <Acept/> } />
                            <Route path="/sharedfile" element = { <SharedFileAcess/> } />
                            <Route path="/backEndParameters" element = { <ReturnBackEndParameters/> } />
                            <Route element = { <ProtectedLayout/> }>
                                <Route path="/" element = { <OrganizationList/>  } />
                                <Route path="/file/:idFile" element = { <LinkToFilePage/>  } />

                            <Route path="/:nameOrg/groups" element = {   <GroupsList />  }  />
                                <Route path="/:nameOrg/groups/:idGroup" element = {   <GroupUsers />  }  />
                                <Route path="/:nameOrg/users" element = {   <ListUserOrg />  }  />
                                <Route path="/:nameOrg" element = { <OrganizationConfig/>  } />
                                <Route path="/:nameOrg/config" element = { <OrganizationConfig/>  } />
                                <Route path="/:nameOrg/WorkSpace" element = {   <WorkSpaceList />  }  />        
                                <Route path="/:nameOrg/WorkSpace/:idWorkspace/Permission" element = {   <WorkSpacePermissions />  }  />
                                <Route path="/:nameOrg/WorkSpace/:idWorkspace/UserPermission" element = {   <WorkSpacePermissionsUser />  }  />
                                <Route path="/:nameOrg/wsallowed" element = {   <WorkSpaceAdmList />  }  />           
                                <Route path="/:nameOrg/dashboard" element = {   <Dashboard />  }  />      

                            <Route path="/:nameOrg/temporality/" element = {   <TemporalityMenu />  }  />
                            <Route path="/:nameOrg/temporality/:idWorkspace" element = {   <TemporalityList />  }  />

                                <Route path="/:nameOrg/wsallowed/:idWorkspace" element = {   <WorkSpaceItemMain />  }  />
                                <Route path="/:nameOrg/wsallowed/:idWorkspace/Permission" element = {   <WorkSpacePermissions />  }  />
                                <Route path="/:nameOrg/wsallowed/:idWorkspace/UserPermission" element = {   <WorkSpacePermissionsUser />  }  />
                                <Route path="/:nameOrg/wsallowed/:idWorkspace/:idFolder" element = {   <WorkSpaceItemMain />  }  />
                                <Route path="/:nameOrg/wsallowed/:idWorkspace/:idFolder/PermissionGroup" element = {   <PermissionGroupFolder />  }  />
                                <Route path="/:nameOrg/wsallowed/:idWorkspace/:idFolder/PermissionUser" element = {   <PermissionUserFolder />  }  />

                                <Route path="/:nameOrg/WorkSpace/:idWorkspace/validationRule" element = {   <ValidationRuleList />  }  />
                                <Route path="/:nameOrg/Workspace/:idWorkspace/validationRule/:idValidationRule" element = { <ValidationRuleCreateEdit/>  } />
                                <Route path="/:nameOrg/WorkSpace/:idWorkspace/documentTypes" element = {   <DocumentTypesList />  }  />
                                <Route path="/:nameOrg/Workspace/:idWorkspace/documetTypes/:idDocumentTypes" element = { <DocumentTypeConfig/>  } />
                                <Route path="/:nameOrg/ValidationRules/UserPendencies" element = {   <UserPendenciesList />  }  />
                                <Route path="/:nameOrg/Item/tracker" element = {   <FeedList />  }  />
                            </Route> 
                            <Route element = { <PublicLayout/> }>
                                <Route path="/wizard" element = { <WizardFreeMain/> } />
                                <Route path="/login" element = { <Login/> } />
                                <Route path="/login/otp/:email" element = { <MfaOTP/> } />
                                <Route path="/login/qrcode/:email/:key" element = { <MfaQRCode /> } />
                                <Route path="/newPassWord" element = { <NewUserPassword/> } />
                                <Route path="/serverDontAllowed" element = { <ServerDontAllowed/> } />
                                <Route path="/twoFaComp" element = { <TwoFaComp/> } />
                                <Route path="/resetPassword" element = { <ResetPassword/> } />
                                <Route path="/emailSendSuccess" element = { <EmailSendSuccess /> } />
                            </Route>
                            <Route path="*" element = { <ErrorPage/> } />
                        </Routes>
                </BrowserRouter>
            </GridProvider>
        </UploadProvider>
    )
 };
 
 export default AppRoutes;