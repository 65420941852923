import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useProfile } from '../../../context/ProfileProvider/useProfile';
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import { Stack } from "@fluentui/react/lib/Stack";
import WorkSpaceAdmListComp from "./WorkSpaceAdmListComp";
import styles from "../../Style/List.module.css";

const TemporalityMenu  = (props: any) => {
  const [reload, setReload] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string>("");

  const profile = useProfile();
  const [isAdminOrganization, setisAdminOrganization] = useState<boolean>(false);

  const { nameOrg } = useParams();

  useEffect(() => {
    if(reload == true ){
      setReload(false)
    }
  }, [reload]);

  useEffect(() => {
    if(profile.organizations != undefined)
    {
      for(let organization of profile.organizations)
      {
        if(nameOrg == organization.internalName)
        {
          if(organization.isAdmin == true)
          {
            
            setisAdminOrganization(true);
          }
          else{
            setisAdminOrganization(false);
          }
        }
      }
    }
  }, [profile]);

  const iconProps = { iconName: "Filter" };

  const onChangeSearchName =
  (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (newValue != undefined ) {
      setSearchName(newValue);
    }
  };


  return (
    <div>
      <Stack horizontal className={styles.hPage} style={{display:"block"}}>   
        <Stack.Item grow>
          <h3 className={styles.hList}>Temporalidade</h3>
        </Stack.Item>     
        <Stack.Item className={styles.InputSearch} style={{display:"flex"}}>
          <div style={{flex: 1, display:"flex", alignItems:"end"}}>
            Selecione um dos workspaces abaixo para visualizar o relatório de temporalidade.
          </div>
          <TextFieldComp iconProps={iconProps}  value={searchName} onChange={onChangeSearchName} />
        </Stack.Item>
      </Stack>
     
      {reload?(<div></div>):(
          nameOrg != undefined?(
            <WorkSpaceAdmListComp
              nameOrg={nameOrg}
              name={searchName}
              hiddenContexMenu={isAdminOrganization == false}
            />
          ):(<li className={styles.itemContent}></li>))}
    </div>
  );
};


export default TemporalityMenu;
