import keyDriveLogo from '../../../../../../assets/images/Icone_KeyDrive.jpg'


export default function FormLogo() {
    return (
        <img 
            src={keyDriveLogo}
            width={30}
        />
    )
}