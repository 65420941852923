import { Typography } from '@mui/material';

export interface FirstTitleProps {
    title: string;
}

export default function FirstTitle({ title }: FirstTitleProps) {
    return (
        <Typography
            variant='h5'
            component="h1"
            fontWeight="bold"
            color="grey"
        >
            {title}
        </Typography>
    )
}