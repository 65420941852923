export interface JpgConfig {}

export const Jpg: React.FC<JpgConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .jpg-1 {
              fill: #fff;
            }

            .jpg-2 {
              fill: none;
              isolation: isolate;
            }

            .jpg-3 {
              fill: #b3b3b3;
            }

            .jpg-3, .jpg-4 {
              fill-rule: evenodd;
            }

            .jpg-4 {
              fill: #d6d6d6;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_752" data-name="Grupo 752">
        <g id="Grupo_737" data-name="Grupo 737">
          <path id="Caminho_136" data-name="Caminho 136" className="jpg-3" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_138" data-name="Caminho 138" className="jpg-4" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="jpg" className="jpg-2">
          <g className="jpg-2">
            <path className="jpg-1" d="m12.62,34.67c0,1.15-.24,2.03-.72,2.66-.48.62-1.17.93-2.07.93-.33,0-.66-.07-.97-.22v-1.36c.3.21.58.31.84.31.89,0,1.34-.79,1.34-2.37v-6.66h1.59v6.71Zm-.78-8.18c-.26,0-.48-.08-.67-.25s-.28-.38-.28-.64.09-.48.28-.65.41-.26.67-.26.49.09.68.26.28.39.28.65c0,.25-.09.46-.28.63-.19.17-.41.26-.68.26Z"/>
            <path className="jpg-1" d="m16.3,33.94h-.03v4.25h-1.59v-10.22h1.59v1.23h.03c.54-.93,1.34-1.39,2.38-1.39.89,0,1.58.31,2.08.94.5.62.75,1.46.75,2.52,0,1.17-.28,2.1-.84,2.8s-1.33,1.05-2.3,1.05c-.89,0-1.58-.39-2.06-1.18Zm-.04-2.76v.87c0,.51.16.94.49,1.3s.74.53,1.24.53c.59,0,1.05-.23,1.38-.69.33-.46.5-1.1.5-1.92,0-.69-.16-1.23-.47-1.62-.31-.39-.73-.58-1.27-.58-.56,0-1.02.2-1.36.6s-.52.9-.52,1.5Z"/>
            <path className="jpg-1" d="m29.5,34.41c0,2.57-1.29,3.86-3.88,3.86-.91,0-1.71-.15-2.39-.46v-1.45c.77.44,1.49.66,2.18.66,1.66,0,2.5-.82,2.5-2.45v-.77h-.03c-.52.89-1.31,1.34-2.37,1.34-.85,0-1.54-.31-2.06-.93-.52-.62-.78-1.46-.78-2.5,0-1.19.28-2.13.84-2.84s1.33-1.05,2.31-1.05c.92,0,1.61.38,2.06,1.13h.03v-.97h1.59v6.44Zm-1.57-2.65v-.91c0-.49-.16-.91-.49-1.26-.33-.35-.73-.52-1.22-.52-.6,0-1.07.22-1.41.67-.34.44-.51,1.06-.51,1.86,0,.69.16,1.24.49,1.65.33.41.76.62,1.29.62s.99-.2,1.33-.59c.34-.39.51-.9.51-1.51Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
