import { Paper, Grid, Box, useTheme } from '@mui/material';
import FormLogo from '../Logo/FormLogo';
import FirstTitle from '../FirstTitle/FirstTitle';
import { FormIcon } from './components/FormIcon/FormIcon';
import FormHeader from '../FormHeader/FormHeader';
import FormPrivacy from '../FormPrivacy/FormPrivacy';

export interface FormRootInstance {
    iconToRender?: string;
    title: string;
    subTitle?: string | undefined;
}

interface FormRootProps {
    children: React.ReactNode;
    instance: FormRootInstance;
}

export default function FormRoot({
    children,
    instance
}: FormRootProps) {

    const theme = useTheme();

    return (
        <Grid 
            container 
            spacing={2}
            style={{
                overflowX:"visible"
            }}
        >
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    gap: 1,
                }}>
                    <FormLogo />
                    <FirstTitle title="KeyDrive" />
                </Box>
            </Grid>
            { instance.iconToRender != undefined && (
                <Grid item xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <FormIcon 
                        icon={instance.iconToRender}
                    />
                </Grid>
            ) }
            <Grid item xs={12}>
                <FormHeader 
                    title={instance.title}
                    subTitle={instance.subTitle}
                />
            </Grid>

            { children }
            
            <Grid item xs={12}>
                <FormPrivacy />
            </Grid>
        </Grid>
    )
}
