import React, { useState, useEffect } from "react";
import WizardFreeFirst from "./WizardFreeFirst";
import WizardFreeEmail from "./WizardFreeEmail";

import { Grid, Snackbar } from "@mui/material";
import { stepOneValidation } from "./validators/stepOneValidation";
import { stepTwoValidation } from "./validators/stepTwoValidation";
import { WizardInformations } from "./WizardInformations";
import Message from "../../components/Message/Message";
import styles from './wizard.module.css'
import { UserTypes } from "./types/userType";
import { WizardModel, WizardToken } from "../../model/WizardModel";
import { CreateWizard, GetWizardToken } from "../../services/WizardServices";
import { useSearchParams, useNavigate } from "react-router-dom";
import MuiAlert, {AlertColor} from '@mui/material/Alert';
import LoaderComp from "../../components/Loader/LoaderComp";
import { useTranslation } from "react-i18next";
import {LeftForm} from "../PublicLayout/components/Handle/LeftForm";
import BtnBigWithIcon, { ButtonTyppe } from "../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import {ArrowIcon} from '../../assets/icons/arrowIcon';
import FormButton from "../PublicLayout/components/Handle/Form/Root/components/FormButton/FormButton";

const WizardFreeMain  = (props: any) => {

    const [currentStep, setCurrentStep] = useState<number>(1)

    const [organization, setOrganization] = useState<string>('');
    const [usernameAdm, setUsernameAdm] = useState<string>('');
    const [workspace, setWorkspace] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [cpass, setCPass] = useState<string>('');

    const [emailOne, setEmailOne] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [emails, setEmails] = useState<UserTypes[]>([])
    const [tokenWizard, setTokenWizard] = useState<WizardToken>()

    const [snackBarMessage, setSnackBarMessage] = useState<string|undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false);
    const [end, setEnd] = useState<boolean>(false);

    const block:boolean = load || end;

    const [searchParams, setSearchParams] = useSearchParams()

    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false)
    const [alertColor, setAlertColor] = useState<AlertColor>("error");

    const [waitSendInformation, setWaitSendInformation] = useState<boolean>(false);

    const [errotToken, setErrotToken] = useState<boolean>(false)
    const { t } = useTranslation()

    const tokenURL = searchParams.get('token')
    const navigate = useNavigate()

    const handleClose = (event:any, reason:any) => {
        setSnackBarMessage(undefined);
    }

    const handleClosePO = (event:any) => {
        setSnackBarMessage(undefined);
    }

    function clearFields() {
        setEmailOne("");
        setName("");
    }

    useEffect(() => {
        setLoading(true)
        if (tokenURL !== undefined || tokenURL !== null) {
            GetWizardToken(tokenURL!)
            .then(data => {
                console.log(data)
                setUsernameAdm(data!.userAdminOrg);
                setTokenWizard(data!);
                setOrganization(data!.orgName);
            })
            .catch(error => {
                setSnackBarMessage(error.response.data);
                setErrotToken(true);
            })
            .finally(() => setLoading(false))
        }
    }, [])

    useEffect(() => {
        setErrorMessage(undefined)
    }, [currentStep])

    const handleChangeOrganization = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setOrganization(newValue || '')
    }
    const handleChangeWorkspace = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setWorkspace(newValue || '')
    }
    const handleChangePassword = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPassword(newValue || '')
    }
    const handleChangeCPassword = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setCPass(newValue || '')
    }

    const handleChangeEmailOne = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setEmailOne(newValue || '')
    }

    const handleChangeName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setName(newValue || '')
    }

    const handleDeleteEmails = (index: number) => {
        setEmails([...emails].filter((_, indexFilter) => indexFilter !== index))
    } 

    const renderSteps = (steps: number) => {
        if (steps !== undefined) {
            if (steps === 1) {
                return <WizardFreeFirst 
                    organization={organization}
                    workspace={workspace}
                    password={password}
                    cpassword={cpass}
                    userExists={tokenWizard?.alreadyRegistered!}
                    errorToken={errotToken}
                    usernameAdm={usernameAdm}
                    isOfficeAuthenticationScheme={tokenWizard?.isOfficeAuthenticationScheme!}

                    handleChangeOrganization={handleChangeOrganization}
                    handleChangeWorkspace={handleChangeWorkspace}
                    handleChangePassword={handleChangePassword}
                    handleChangeCPassword={handleChangeCPassword}
                />
            }

            if (steps === 2) {
                return <WizardInformations 
                    organization={organization}
                    workspace={workspace}
                    password={password}
                    cpassword={cpass}
                    emailOne={emailOne}
                    emails={emails}
                    gotToChangeEmails={() => setCurrentStep(3)}
                    handleDelete={handleDeleteEmails}
                />
            }

            if (steps === 3) {
                return <WizardFreeEmail 
                    emailOne={emailOne}
                    handleChangeEmailOne={handleChangeEmailOne}
                    emails={emails}
                    handleDelete={handleDeleteEmails}
                    setEmails={setEmails}
                    handleChangeName={handleChangeName}
                    name={name}
                    clearFields={clearFields}
                />
            }
        }
    }

    const hops = (steps: number) => {
        if (steps !== undefined) {
            setCurrentStep(steps + 1)
        }
    }

    const backs = (steps: number) => {
        setEmailOne("");
        setName("");

        if (steps !== undefined) {
            setCurrentStep(steps - 1)
        }
    }

    const shuffleValidation = (step: number) => {
        if (step === 1) {
            const error = stepOneValidation(organization, workspace, password, cpass, () => hops(currentStep), tokenWizard?.alreadyRegistered!, tokenWizard?.isOfficeAuthenticationScheme!)
            if (error != undefined) {
                setErrorMessage(error)
            }
        }
        if (step === 2) {
           const error = stepTwoValidation(emails, () => hops(currentStep))
           if (!error) {
            setErrorMessage(undefined)
           }
        }
    }

    return (
        <>
        <LeftForm
            formRootInstance={{
                title:t("Wizard.welcome")
            }}
        >    
            { loading && currentStep < 3 ? (
                <Grid item sx={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    width: '100%'
                }}>
                    <LoaderComp />
                </Grid>
            ) : (
                <>
                    { renderSteps(currentStep) }

                    { (errorMessage != undefined) && (
                        <Grid item xs={12} sx={{ padding: '10px' }} className={styles.wizard__anim__enter}>
                            <Message 
                                text={errorMessage}
                                type={1}
                            />
                        </Grid>
                    ) }
                    
                    {waitSendInformation == false && (
                        <>
                            {(currentStep === 1 || currentStep === 2) && (
                                <Grid item xs={12}>
                                    <FormButton 
                                        load={load}
                                        btnBigWithIconConfig={{
                                            disabled:block,
                                            text:currentStep === 2 ? t("Wizard.conclude") : t("Wizard.continue"),
                                            display:errotToken,
                                            onClick:() => {
                                                if (currentStep !== 2) {
                                                    shuffleValidation(currentStep)
                                                    return 
                                                }
                                                else
                                                {
                                                    setLoad(true);
                                                    const createWizard: WizardModel = {
                                                        organizationName: organization,
                                                        password: password,
                                                        users: emails,
                                                        workspaceName: workspace,
                                                        token: tokenWizard!.token!
                                                    }
                                                    
                                                    CreateWizard(createWizard)
                                                    .then(data => {
                                                        setAlertColor('success')
                                                        setSnackBarMessage('Dados cadastrados com sucesso!')

                                                        setEnd(true);
                                                        setInterval(() => {
                                                            navigate('/login');
                                                        }, 3000)
                                                    })
                                                    .catch(error => {
                                                        setAlertColor('error')
                                                        setSnackBarMessage(error.response.data)
                                                    })
                                                    .finally(() => {
                                                        setLoad(false);
                                                    })
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <BtnBigWithIcon 
                                    isPrimary={ButtonTyppe.secondary}
                                    text={t("Wizard.return")}
                                    onClick={() => backs(currentStep)}
                                    display={errotToken || (currentStep === 1 ? true : false)}
                                    icon={ArrowIcon}
                                    disabled={block}
                                />
                            </Grid>
                        </>
                    )}
                </>
            )} 
        </LeftForm>
            <Snackbar
                open={snackBarMessage != undefined}
                autoHideDuration={6000}
                message="Archived"
                onClose={handleClose}
                anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
                sx={{ bottom: { xs: 50, sm: 20 } }}
            >
                <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
                    {snackBarMessage}
                </MuiAlert>
            </Snackbar>
        </>
    )
}

export default WizardFreeMain;
