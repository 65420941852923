export interface GifConfig {}

export const Gif: React.FC<GifConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .gif-1 {
              fill: #fff;
            }

            .gif-2 {
              fill: none;
              isolation: isolate;
            }

            .gif-3 {
              fill: #b3b3b3;
            }

            .gif-3, .gif-4 {
              fill-rule: evenodd;
            }

            .gif-4 {
              fill: #d6d6d6;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_750" data-name="Grupo 750">
        <g id="Grupo_735" data-name="Grupo 735">
          <path id="Caminho_154" data-name="Caminho 154" className="gif-3" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_156" data-name="Caminho 156" className="gif-4" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="gif" className="gif-2">
          <g className="gif-2">
            <path className="gif-1" d="m18.4,34.41c0,2.57-1.29,3.86-3.88,3.86-.91,0-1.71-.15-2.39-.46v-1.45c.77.44,1.49.66,2.18.66,1.66,0,2.5-.82,2.5-2.45v-.77h-.03c-.52.89-1.31,1.34-2.37,1.34-.85,0-1.54-.31-2.06-.93-.52-.62-.78-1.46-.78-2.5,0-1.19.28-2.13.84-2.84s1.33-1.05,2.31-1.05c.93,0,1.61.38,2.06,1.13h.03v-.97h1.59v6.44Zm-1.57-2.65v-.91c0-.49-.16-.91-.49-1.26-.33-.35-.73-.52-1.22-.52-.6,0-1.07.22-1.41.67-.34.44-.51,1.06-.51,1.86,0,.69.16,1.24.49,1.65.33.41.76.62,1.3.62s.99-.2,1.33-.59c.34-.39.51-.9.51-1.51Z"/>
            <path className="gif-1" d="m21.27,26.5c-.26,0-.48-.08-.67-.25s-.28-.38-.28-.64.09-.48.28-.65.41-.26.67-.26.5.09.68.26.28.39.28.65c0,.25-.09.46-.28.63-.19.17-.41.26-.68.26Zm.79,8.47h-1.59v-7h1.59v7Z"/>
            <path className="gif-1" d="m28.08,25.9c-.21-.12-.46-.18-.73-.18-.77,0-1.16.44-1.16,1.31v.95h1.63v1.24h-1.62v5.76h-1.59v-5.76h-1.2v-1.24h1.2v-1.13c0-.74.24-1.32.72-1.75.48-.43,1.09-.64,1.81-.64.39,0,.7.04.93.13v1.32Z"/>
          </g>
        </g>
      </g>
    </svg>

  )
}
