import {Checkbox, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import TextFieldComp from "../../components/TextField/TextFieldComp";
import BtnBigWithIcon, {ButtonTyppe} from "../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import { validEmail,nameValidation } from "./validators/stepTwoValidation";
import { UserTypes } from "./types/userType";
import { WizardUserCard } from "./components/WizardUserCard";
import { useTranslation } from "react-i18next";
import styles from './wizard.module.css'
import Message from "../../components/Message/Message";
import { LoginType } from "../../utils/constants/LoginTypeConstants";
import CheclkBoxComp from "../../components/Checkbox/CheckboxComp";

export interface WizardFreeEmailParams{
    emailOne: string
    handleChangeEmailOne: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void
    handleChangeName: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void
    emails: UserTypes[]
    handleDelete: (index: number) => void
    setEmails: React.Dispatch<React.SetStateAction<UserTypes[]>>
    name: string;
    clearFields: () => void;
}

const WizardFreeEmail: React.FC<WizardFreeEmailParams> = ({
    emailOne,
    handleChangeEmailOne,
    handleChangeName,
    emails,
    handleDelete,
    setEmails,
    name,
    clearFields
}) => {

    const { t } = useTranslation();
    const [isEmailError, setIsEmailError] = useState<string|undefined>(undefined);
    const [loginType, setLoginType] = useState<number>(LoginType.EmailPassword);

    function checkEmailOnList(emails: UserTypes[], emailToSearch: string) {
        if (emails.length > 0) {
            if (emails.flatMap(x => x.email).includes(emailToSearch)) {
                setIsEmailError(t("Wizard.emailAlreadyInList"));
                clearFields();
                throw new Error();
            }
        }   
    }

      
    function changeLoginMicrosoftUser (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if(checked == undefined || checked == false)
        {
            setLoginType(LoginType.EmailPassword);
        }
        else
        {
            setLoginType(LoginType.Office365);
        }
    };

    function handleDeleteInternal (index: number)
    {
        setIsEmailError(undefined)
        handleDelete(index);
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    sx={{
                    textAlign: "left",
                    font: "normal normal 600 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    }}
                >
                    {t("Wizard.name")}
                </Typography>
                <TextFieldComp 
                    onChange={handleChangeName}
                    value={name}
                    bigSize={true}
                    round={true}
                    grow={true}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                    textAlign: "left",
                    font: "normal normal 600 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    }}
                >
                    Email
                </Typography>
                <TextFieldComp 
                    onChange={handleChangeEmailOne}
                    value={emailOne}
                    bigSize={true}
                    round={true}
                    grow={true}
                />
            </Grid>
            <Grid item xs={12}
                display="flex"
                alignItems="center"
            >
                <CheclkBoxComp 
                    checked={loginType == LoginType.Office365}
                    onChange={changeLoginMicrosoftUser}
                />
                <Typography
                    sx={{
                    textAlign: "left",
                    font: "normal normal 600 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    }}
                >
                    {t("Login com microsoft")}
                </Typography>
            </Grid>
            { (isEmailError != undefined) && (
                <Grid item xs={12} sx={{ padding: '10px' }} className={styles.wizard__anim__enter}>
                    <Message 
                        text={isEmailError}
                        type={1}
                    />
                </Grid>
            ) }            
            {emails.length > 0 && (
                <Grid item xs={12}>
                    { emails.map(({ name, email }, index) => (
                            <WizardUserCard 
                                email={email}
                                name={name}
                                handleDelete={handleDeleteInternal}
                                index={index}
                                key={index}
                            />
                        ))
                    }
                </Grid>
            )}
            <Grid item xs={12}>
                <BtnBigWithIcon 
                        isPrimary={ButtonTyppe.primary}
                        text={t("Wizard.addUsers")}
                        onClick={() => {
                        if (emails.length < 5) {
                            checkEmailOnList(emails, emailOne);
                            if (validEmail(emailOne) && nameValidation(name)) {
                                setIsEmailError(undefined);
                                setEmails([...emails, { name: name, email: emailOne, loginType: loginType }]);
                                clearFields();
                                setLoginType(LoginType.EmailPassword);
                            } else {
                                setIsEmailError(t("Wizard.fillAllFields"))
                            }
                        } else {
                            setIsEmailError(t("Wizard.maxUserlength"));
                        }
                    }}
                    disabled={isEmailError != undefined}
                />
            </Grid>
        </>
    );
}

export default WizardFreeEmail;