import checkConfirmEmail from '../../../assets/icons/check-confirm-email.svg'
import { Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from 'react-router-dom'
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import {LeftForm} from "../../PublicLayout/components/Handle/LeftForm";
import BtnBigWithIcon,{ ButtonTyppe} from "../../../components/Button/BtnBigWithIcon/BtnBigWithIcon";
import {FormLink} from "../../PublicLayout/components/Handle/Form/Root/components/FormLink/FormLink";

export default function EmailSendSuccess() {

  const navigate = useNavigate()
  const { t } = useTranslation();
  const theme = useTheme();

  
  const login = (event: any) => {
    navigate("/login");
  };

  return (
    <LeftForm
      formRootInstance={{
          title:t("Login.checkYourEmail"),
          subTitle:t("Login.confirmationEmail")
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography>{t("Login.didntRecieved")} <FormLink href="/resetPassword" link={t("Login.clickHereToResend")} underline="hover" /></Typography>
        </Grid>
        <BtnBigWithIcon 
          isPrimary={ButtonTyppe.secondary}
          text={t("IrParaLogin.message")}
          onClick={login}
        />
      </Grid>
    </LeftForm>
      
  )
}