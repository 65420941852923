import React, { useState } from "react";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import { useTranslation } from "react-i18next";
import { RenameItem } from "../../../model/ItemModel";
import { Rename } from "../../../services/Item/ItemServicesCRUD";
import  Btn  from '../../../components/Button/Btn';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export interface RenameConfig{
    itemId: string;
    nameChanged: string;
    extensionChanged: string|undefined;
    isFolder: boolean;
}

export interface RenameParams{
    saveOkMetod: () => void
    renameConfig:RenameConfig
}

const RenameComp = (props: RenameParams) => {
    const { t } = useTranslation(); 
    const [nameChanged, setNameChanged] = useState<string>(props.renameConfig.nameChanged);
    const [extensionChanged, setExtensionChanged] = useState<string|undefined>(props.renameConfig.extensionChanged);
    const [messageErrorString, setMessageErrorString] = useState<string>(t("ErroAoSalvar.message"));
    const [wait, setWait] = useState<boolean>(false);
	const [popupError, setPopupError] = useState<boolean>(false);
    
    const onChangeSetName = React.useCallback(
        (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
        ) => {
            if (newValue != undefined) {
                if (newValue.includes("\\") || newValue.includes("/")) {
                    setPopupError(true)
                    setMessageErrorString(t("NameMustNotIncludeEitherSlashes.message"));
                } else {
                    setPopupError(false)
                    setMessageErrorString("");
                    setNameChanged(newValue);
                }
            }
            else
            {
                setNameChanged("");
            }
        },
        []
    );

    const onChangeSetExtension = React.useCallback(
        (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
        ) => {
            if (newValue != undefined) {
                if (newValue.includes("\\") || newValue.includes("/")) {
                    setPopupError(true)
                    setMessageErrorString(t("NameMustNotIncludeEitherSlashes.message"));
                } else {
                    setPopupError(false)
                    setMessageErrorString("");
                    setExtensionChanged(newValue);                }
            }
            else
            {
                setExtensionChanged("");
            }
        },
        []
    );

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key ===  'Enter'){
            //rename();
        }
    }  

    const rename = () => {
        let rename:RenameItem = {
            idMongo:props.renameConfig.itemId,
            newName:nameChanged,
            newFileExtension:extensionChanged
        };
        Rename(rename)
        .then((result) => {
            props.saveOkMetod();
        })
        .catch((error) => {
            if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setMessageErrorString(error.response.data);
            }
            else
            {
                setMessageErrorString(t("ErroAoSalvar.message"));
            }
            setPopupError(true);
        })
        .finally(()=>{
            setWait(false);
        });
    };

    const handleClose = (event: any, reason: any) => {
        closePopUpOk();
    };
    const handleClosePO = (event: any) => {
        closePopUpOk();
    };
    function closePopUpOk() {
        setPopupError(false);
    }

    return (
        <div>
            <TextFieldComp
                label={t("Nome.message")}
                value={nameChanged}
                onChange={onChangeSetName}
                autofocus
                onKeyDown={onKeyDown}
            />
            <br/>
            <TextFieldComp
                label={t("Extencao.message")}
                value={extensionChanged}
                onChange={onChangeSetExtension}
                onKeyDown={onKeyDown}
                disabled={props.renameConfig.isFolder}  
            />      
            <Btn onClick={rename} text={t("renameItem.message")} />

            <Snackbar
	        open={popupError}
	        autoHideDuration={6000}
	        message="Archived"
	        onClose={handleClose}
	        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
	        sx={{ bottom: { xs: 50, sm: 20 } }}
            >
	            <MuiAlert
                    elevation={6}
                    variant="filled"
                    sx={{ width: "100%" }}
                    severity="error"
                    onClose={handleClosePO}
	            >
		            {messageErrorString}
	            </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default RenameComp;