import { TextField } from "@fluentui/react";
import { Grid } from "@mui/material";
import React from "react";
import { Typography } from '@mui/material'
import { useTranslation } from "react-i18next";

export interface WizardFreeFirstParams{
    organization: string,
    workspace: string,
    password: string,
    cpassword: string,
    userExists: boolean,
    errorToken: boolean,
    usernameAdm:string,
    isOfficeAuthenticationScheme: boolean,

    handleChangeOrganization: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void,
    handleChangeWorkspace: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void,
    handleChangePassword: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void,
    handleChangeCPassword: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void,
}

const WizardFreeFirst: React.FC<WizardFreeFirstParams> = ({
    handleChangeCPassword,
    handleChangeOrganization,
    handleChangePassword,
    handleChangeWorkspace,
    organization,
    workspace,
    password,
    cpassword,
    userExists,
    errorToken,
    usernameAdm,
    isOfficeAuthenticationScheme
}) => {

    const { t } = useTranslation();

    const handleTextFields = (userAlreadyExists: boolean, isOfficeAuthenticationScheme: boolean) => {
        
        if (!userAlreadyExists && !isOfficeAuthenticationScheme) {
            return (
                <>       
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                            textAlign: "left",
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 1,
                            }}
                        >
                            {t("Wizard.password")}
                        </Typography>
                        <TextField 
                            onChange={handleChangePassword}
                            value={password}
                            styles={{
                            fieldGroup: {
                                background: "#FBFBFB",
                                border: "2px solid #2D2D2D4D",
                                borderRadius: "8px",
                                opacity: 1,
                                height: '54px'
                            }
                            }}
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                            textAlign: "left",
                            font: "normal normal 600 16px/24px Segoe UI",
                            letterSpacing: "0px",
                            color: "#2D2D2D",
                            opacity: 1,
                            }}
                        >
                            {t("Wizard.confirmPassword")}
                        </Typography>
                        <TextField 
                            onChange={handleChangeCPassword}
                            value={cpassword}
                            styles={{
                            fieldGroup: {
                                background: "#FBFBFB",
                                border: "2px solid #2D2D2D4D",
                                borderRadius: "8px",
                                opacity: 1,
                                height: '54px'
                            }
                            }}
                            type="password"
                        />
                    </Grid>
                </>
            )
        }

        return <></>
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography
                    sx={{
                    textAlign: "left",
                    font: "normal normal 600 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    }}
                >
                    {t("Wizard.organization")}
                </Typography>
                <TextField 
                    onChange={handleChangeOrganization}
                    value={organization}
                    styles={{
                    fieldGroup: {
                        background: "#FBFBFB",
                        border: "2px solid #2D2D2D4D",
                        borderRadius: "8px",
                        opacity: 1,
                        height: '54px'
                    }
                    }}
                    disabled={errorToken}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{
                    textAlign: "left",
                    font: "normal normal 600 16px/24px Segoe UI",
                    letterSpacing: "0px",
                    color: "#2D2D2D",
                    opacity: 1,
                    }}
                >
                    Workspace
                </Typography>
                <TextField 
                    onChange={handleChangeWorkspace}
                    value={workspace}
                    styles={{
                    fieldGroup: {
                        background: "#FBFBFB",
                        border: "2px solid #2D2D2D4D",
                        borderRadius: "8px",
                        opacity: 1,
                        height: '54px'
                    }
                    }}
                    disabled={errorToken}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField 
                    onChange={handleChangePassword}
                    value={usernameAdm}
                    styles={{
                    fieldGroup: {
                        background: "#FBFBFB",
                        border: "2px solid #2D2D2D4D",
                        borderRadius: "8px",
                        opacity: 1,
                        height: '54px'
                    }
                    }}
                    disabled
                />
            </Grid>  
            { handleTextFields(userExists, isOfficeAuthenticationScheme) }
        </>
    );
}

export default WizardFreeFirst;