export interface XlsConfig {}

export const Xls: React.FC<XlsConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .xls-1 {
              fill: #fff;
            }

            .xls-2 {
              fill: #00733b;
            }

            .xls-2, .xls-3 {
              fill-rule: evenodd;
            }

            .xls-4 {
              fill: none;
              isolation: isolate;
            }

            .xls-3 {
              fill: #4d9d76;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_743" data-name="Grupo 743">
        <g id="Grupo_728" data-name="Grupo 728">
          <path id="Caminho_136" data-name="Caminho 136" className="xls-2" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_138" data-name="Caminho 138" className="xls-3" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="xls" className="xls-4">
          <g className="xls-4">
            <path className="xls-1" d="m17.88,27.97l-2.33,3.54,2.3,3.46h-1.84l-1.23-2.17c-.08-.13-.17-.3-.27-.51h-.03s-.11.21-.29.51l-1.25,2.17h-1.83l2.38-3.43-2.26-3.57h1.85l1.21,2.28c.09.17.18.34.27.52h.02l1.57-2.8h1.74Z"/>
            <path className="xls-1" d="m20.64,34.97h-1.59v-10.36h1.59v10.36Z"/>
            <path className="xls-1" d="m22.27,34.75v-1.47c.59.45,1.25.68,1.96.68.96,0,1.44-.28,1.44-.85,0-.16-.04-.29-.12-.41-.08-.11-.19-.21-.33-.3-.14-.09-.3-.16-.49-.23-.19-.07-.4-.15-.63-.23-.29-.11-.55-.23-.78-.36-.23-.13-.42-.27-.57-.43-.15-.16-.27-.35-.35-.55s-.12-.44-.12-.72c0-.34.08-.63.24-.89.16-.26.37-.47.64-.65.27-.17.57-.31.92-.4.34-.09.7-.13,1.06-.13.65,0,1.23.1,1.74.29v1.39c-.49-.34-1.06-.51-1.7-.51-.2,0-.38.02-.54.06-.16.04-.3.1-.41.17s-.2.16-.27.26c-.06.1-.1.21-.1.34,0,.15.03.28.1.38s.16.2.28.28c.13.08.28.15.45.22.18.07.38.14.61.21.3.12.57.25.81.38.24.13.44.27.61.43.17.16.3.35.39.56.09.21.14.46.14.75,0,.36-.08.67-.25.93s-.38.48-.66.66-.59.3-.95.39c-.36.08-.74.13-1.13.13-.77,0-1.43-.13-1.99-.38Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
