import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { REGEX_CONSTANTS } from './Regex/RegexUtils'
import { PasswordActions, PasswordState } from "./Types/PasswordTypes";
import PasswordWarning from "../PasswordLoginWarning/components/PasswordWarning";

interface RealTimePasswordCheckProps {
    passwordConfirm: string;
    password: string;
    state: PasswordState;
    dispatch: React.Dispatch<PasswordActions>;
}

export function RealTimePasswordCheck({
    password,
    passwordConfirm,
    state, 
    dispatch
}: RealTimePasswordCheckProps) {

    const { NUMBER_REGEX, SPECIAL_REGEX, UPPERCASE_REGEX } = REGEX_CONSTANTS;

    useEffect(() => {
        
        if (password.length >= 6) {
            dispatch({ type: "min" });
        } else {
            dispatch({ type: 'clearMin' });
        }

        if (SPECIAL_REGEX.test(password)) {
            dispatch({ type: 'special' });
        } else {
            dispatch({ type: 'clearSpecial' });
        }

        if (UPPERCASE_REGEX.test(password)) {
            dispatch({ type: 'capital' });
        } else {
            dispatch({ type: 'clearCapital' });
        }

        if (NUMBER_REGEX.test(password)) {
            dispatch({ type: 'numberC' });
        } else {
            dispatch({ type: 'clearNumberC' });
        }

    }, [password, passwordConfirm])

    return (
        <Grid container sx={{
            marginBottom: '20px'
        }}>
            <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '23px'
            }}>
                <Box id="warning-1" sx={{
                    width: '250px'
                }}>
                    <PasswordWarning
                        state={state} 
                        variant='capitalCharacter'
                    />
                </Box>
                <Box id="warning-2" sx={{
                    width: '250px'
                }}>
                    <PasswordWarning
                        state={state}
                        variant='specialCharacter'
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'start'
            }}>
                <Box id="warning-3" sx={{
                    width: '250px'
                }}>
                    <PasswordWarning
                        state={state} 
                        variant="minimunCharacters"
                    />
                </Box>
                <Box id="warning-4" sx={{
                    width: '250px'
                }}>
                    <PasswordWarning
                        state={state} 
                        variant="numberCharacter"
                    />
                </Box>
            </Grid>
        </Grid>
    )
}