import { mergeStyleSets } from "@fluentui/react";
import {
	GridColumn,
	ReturnColumnSizeUserPendencies,
	standartGridColumnBegin,
	standartGridColumnEnd,
	standartGridSizeBegin,
	standartGridSizeEnd,
	description,
	validationRule,
	createdAt,
	size,
	tags,
	term,
	name
} from "./ColumnStandard";
import { concatString } from "./Concat";

export const paddingStandart: string = "15px";

export function returnStyles(
	layoutPendencies: boolean,
	gridDragged: GridColumn[]
) {
	let contentStyles = mergeStyleSets({
		gridTitle: {
			display: "grid",
			gridTemplateColumns: returnStyleGrid(layoutPendencies, gridDragged).gridColumn,
			gridTemplateAreas: returnStyleGrid(layoutPendencies, gridDragged).gridArea,
			borderBottom: "1px solid #b5b5b5",
			padding: paddingStandart,
			height: "63px",
			overflowX: "auto",
			overflowY: "hidden",
			position: "relative",
		},

		gridBody: {
			display: "grid",
			gridTemplateColumns: returnStyleGrid(layoutPendencies, gridDragged).gridColumn,
			gridTemplateAreas: returnStyleGrid(layoutPendencies, gridDragged).gridArea,
			position: "relative",
			padding: paddingStandart,
			borderBottom: "1px solid #dcdada",
			fontWeight: 600,
		},
	});

	return contentStyles;
}

function returnStyleGrid(
	layoutPendencies: boolean,
	arrayGrid: GridColumn[]
) {	
	if (layoutPendencies) {
		let columns = ReturnColumnSizeUserPendencies();
		return {
			gridArea: concatString(columns.map(column => column.name), true, true),
			gridColumn: concatString(columns.map(column => column.length), false, false),
		};
	}
	
	let gridAreaString:string = standartGridColumnBegin;
	let gridColumnString:string = standartGridSizeBegin;

	let nameField = arrayGrid.find(x => x.name == name && x.deleted == false)
	if(nameField != undefined)
	{
		gridAreaString = gridAreaString + " " + nameField.name;
		gridColumnString = gridColumnString + " " + nameField.length;
	}

	let descriptionField = arrayGrid.find(x => x.name == description && x.deleted == false)
	if(descriptionField != undefined)
	{
		gridAreaString = gridAreaString + " " + descriptionField.name;
		gridColumnString = gridColumnString + " " + descriptionField.length;
	}

	let validationRuleField = arrayGrid.find(x => x.name == validationRule && x.deleted == false)
	if(validationRuleField != undefined)
	{
		gridAreaString = gridAreaString + " " + validationRuleField.name;
		gridColumnString = gridColumnString + " " + validationRuleField.length;
	}
	
	let createdAtField = arrayGrid.find(x => x.name == createdAt && x.deleted == false)
	if(createdAtField != undefined)
	{
		gridAreaString = gridAreaString + " " + createdAtField.name;
		gridColumnString = gridColumnString + " " + createdAtField.length;
	}

	let tagsField = arrayGrid.find(x => x.name == tags && x.deleted == false)
	if(tagsField != undefined)
	{
		gridAreaString = gridAreaString + " " + tagsField.name;
		gridColumnString = gridColumnString + " " + tagsField.length;
	}

	let sizeField = arrayGrid.find(x => x.name == size && x.deleted == false)
	if(sizeField != undefined)
	{
		gridAreaString = gridAreaString + " " + sizeField.name;
		gridColumnString = gridColumnString + " " + sizeField.length;
	}

	let termField = arrayGrid.find(x => x.name == term && x.deleted == false)
	if(termField != undefined)
	{
		gridAreaString = gridAreaString + " " + termField.name;
		gridColumnString = gridColumnString + " " + termField.length;
	}

	// for (var valArrayGrid of arrayGrid) {
	// 	if(valArrayGrid.deleted == false)
	// 	{
	// 		gridAreaString = gridAreaString + " " + valArrayGrid.name;
	// 		gridColumnString = gridColumnString + " " + valArrayGrid.length;
	// 	}
	// }

	gridAreaString = gridAreaString + standartGridColumnEnd;
	gridColumnString = gridColumnString + standartGridSizeEnd;

	return {
		gridArea: gridAreaString,
		gridColumn: gridColumnString,
	};
}
