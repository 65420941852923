import { ThemeProvider, Tooltip, createTheme, Box } from "@mui/material"
import { BaseSvg } from "../BaseSVg/BaseSvg";
import {term} from "../../pages/WorkSpaceItem/WorkSpaceItemListStyle/ColumnStandard"

export interface IIconCompTypes {
  tooltip?: string
  type: 'OUT' | 'ON' | 'CURRENT'
  opacity?: string
}

export const IconComp: React.FC<IIconCompTypes> = ({ tooltip, type, opacity }) => {

  const handleTypeIcon = (type: 'OUT' | 'ON' | 'CURRENT'): string => {
    switch(type) {
        case "ON":
          return "#114d88";
        case "OUT":
          return "#d11422";
        case "CURRENT":
          return "#E1972B";
    }
  }

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: handleTypeIcon(type), 
            color: 'white', 
          },
        },
      },
    },
  });
  
  return (
    <div
      style={{
        gridArea: term,
        display: 'flex', alignItems: 'center', justifyContent: 'center'
      }}
    >
      <ThemeProvider theme={theme}>
        <Tooltip
          title={tooltip}
          placement="bottom"
        >
          <Box component='span'>
            <BaseSvg 
              color={handleTypeIcon(type)}
              width={"20"}
              height={"20"}
              opacity={opacity}
            />
          </Box>
        </Tooltip>
      </ThemeProvider>
    </div> 
  )
}