import React, { useState, useEffect,useRef,WheelEvent } from "react";
import {MenuConfig} from "../../../components/Menu/MenuConfig";
import Loader from 'react-ts-loaders';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import PDFPageViwer from "../../../components/PDF/PDFViewer/PDFPageViwer"
import ImagePageViwer from "../../../components/Image/ImagePageViwer"
import DwgViwerPng from "../../../components/DWG/DwgViwerPng";
import { mergeStyleSets } from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import { ShareFileTokenReturn, WorkSpaceUserName } from "../../../model/ItemModel";
import NoViwerPage from "./NoViwerPage";
import { IShowInfo } from "../../DocumentDetail/DocumentDetail";
import { Box } from "@mui/material";
import FieldDwg from "./SettingFileType/FileDwg"
import { ParameterViwer } from "./ParameterViwer"
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import printJS from "print-js";
import ErrorViwer from "./ErrorViwer";
import { ModalStyled } from "../../../components/StyledModal/StyledModal"; 

export interface ChoicerViwerProps{
    idItem: string;
    download(idItem: string, parameterViwerPdw?: ParameterViwer, onDownloadProgress?: ((progressEvent: any) => void) | undefined): Promise<any>;
    getItem(idItem: string, onDownloadProgress?: ((progressEvent: any) => void) | undefined): Promise<ShareFileTokenReturn>;
    menuConfig?: MenuConfig;
    positionAbsolute:boolean;
    showList?:boolean;
    title?: string;
    isAcept: boolean;
    isFilePage?: boolean;
    cancel?: () => void
    toggleHideDialog?: () => void;
    changeModalContent?: (id: string, name: string, extension: string) => void;
    setShowViwerInfo?: (set: IShowInfo) => void;
    isDetailPage?: boolean;
    fullScreenSetted: boolean;
    isSidebarCollapsed?: boolean;
    isSharedFilePage: boolean;
}

const loaderSave = mergeStyles({
    justifyContent:"left",
    width:"50px",
    paddingTop:"10px",
    paddingBottom:"10px"
  });

export enum ViewerOptions {
    Pdf = 'Pdf',
    Image = "Image",
    DwgImage = "DwgImage",
    NoViewer = "NoViewer",
    ErrorViwer = "ErrorViwer"
};

const ChoicerViwer  = (props: ChoicerViwerProps) => {
    const { t } = useTranslation();
    const [progress, setProgress] = useState<number>(0);
    const [url, setUrl] = useState<string| undefined>(undefined);

    const [urlMultiFile, setUrlMultiFile] = useState<string[]>([]);

    const [name, setName] = useState<string|undefined>(undefined);
    const [extension, setExtension] = useState<string|undefined>(undefined);
    const [typeViewer, setTypeViewer] = useState<ViewerOptions|undefined>(undefined);
    const [loader, setLoader] = useState<boolean>(true);
    const [styleState, setStyleState] = useState<string>("");

    const [hideSidebar, { toggle: toggleHideSidebar }] = useBoolean(false);
    const [isFileConfigOpen, setIsFileConfigOpen] = useState<boolean>(false);

    const [snackBarMessage, setSnackBarMessage] = useState<string|undefined>(undefined);
    const [isModalViwer, setIsModalViwer] = useState<boolean>(false);
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    
    const previewWorkspaceViewerHeight = 54;

    const onDownloadProgress = (progressEvent:any) => {
        if(Math.round((progressEvent.loaded * 100) / progressEvent.total) === 100) setLoader(false)
        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    };

    useEffect(() => {
        let isViwerModal = !props.isAcept && !props.isDetailPage && !props.isFilePage;
        setIsModalViwer(isViwerModal);
    }, [props.isAcept, props.isDetailPage, props.isFilePage])

    const contentStyles = mergeStyleSets({
        loader:{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        Progressao:{
            fontSize:"30px"
        }
    })

    function erroManagement(error:any)
    {
        if( error.response && error.response.data && typeof error.response.data  === 'string'){
            let blob = new Blob([error.response.data]);
            blob.text()
            .then((message) => {
                console.log(message);
            })
            .catch(() => {
                if(props.setShowViwerInfo != undefined )
                    props.setShowViwerInfo({ value: '1', isShown: true });
            });
        }
        else
        {
            if(props.setShowViwerInfo != undefined )
                props.setShowViwerInfo({ value: '1', isShown: true });
        }
    }

    const downloadViwerDwg = (idItem: string, onClick: boolean, zoom:number, first:boolean) => {
        if(first)
            setLoader(true);

        const parameterViewerDwg: ParameterViwer = {
                    converterType: 1,
                    backgroundColors : 1,
                    zoom:zoom
        }
        props.download(idItem, parameterViewerDwg, onDownloadProgress)
        .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob], {type: 'application/pdf'}),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name + "." + extension,
                );
                if(onClick){
                    link.click()
                } else {
                    setUrl(url);                   
                }
                let arrayDWG = urlMultiFile;
                arrayDWG.push(url);
                setUrlMultiFile(arrayDWG);
                if(zoom < 3)
                    downloadViwerDwg(idItem,false, zoom + 1, false);

                link.parentNode?.removeChild(link);
            }).catch((error) =>{
                setLoader(false);
                setTypeViewer(ViewerOptions.ErrorViwer);
                erroManagement(error);
                if(props.setShowViwerInfo != undefined)
                    props.setShowViwerInfo({ value: '1', isShown: true });
            }).finally(() => {
                if(zoom == 3)
                    setLoader(false);
            });
    }

    function download()
    {
        downloadViwer(props.idItem, false);
    }

    const downloadViwer = (idItem: string, onClick: boolean, parameterViewerDwg?: ParameterViwer) => {
        setLoader(true);
        props.download(idItem, parameterViewerDwg, onDownloadProgress)
        .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob], {type: 'application/pdf'}),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name + "." + extension,
                );
                if(onClick){
                    link.click()
                } else {
                    setUrl(url);                   
                }
                link.parentNode?.removeChild(link);
            }).catch((error) =>{
                setTypeViewer(ViewerOptions.ErrorViwer);
                erroManagement(error);

                if(props.setShowViwerInfo != undefined)
                    props.setShowViwerInfo({ value: '1', isShown: true });
            }).finally(() => {
                setLoader(false);
            });
    }
   
    const itemHaveViwer = (extension: string) => {
        if(extension !="pdf" && extension !="dwg" && extension !="png" &&extension !="jpg" && extension!="gif" && extension!="svg"&& 
            extension!="bmp" && extension!="webp" && extension!="docx" && extension!="doc" && extension!="dwg" && 
            extension != "xlsx" && extension != "ppt" && extension != "pptx" && extension != "mpp" && extension != "xls")
        {
            return false
        } else {
            return true
        }
    }
    
    useEffect(() => {
        setUrl(undefined);
        setUrlMultiFile([]);

        props.getItem(props.idItem)
        .then((returnJson) =>{
            setName(returnJson.name);
            setExtension(returnJson.extension);
            let parameterViewerDwgDefault: ParameterViwer ={};

                if (returnJson.extension && itemHaveViwer(returnJson.extension)) {
                    if (returnJson.extension == "dwg") {
                        parameterViewerDwgDefault = {
                            converterType: 1,
                            backgroundColors : 1,
                            zoom:0
                        }

                        downloadViwerDwg(props.idItem,false, 0, true)
                    } else if(itemHaveViwer(returnJson.extension)){
                        downloadViwer(props.idItem, false, undefined);
                    }
                    setTypeViewer(returnTypeViewer(returnJson.extension, parameterViewerDwgDefault))

                }
            })
            .catch((error) => {
                setTypeViewer(ViewerOptions.ErrorViwer);
                erroManagement(error);
                props.setShowViwerInfo!({ value: '1', isShown: true });
            });

    }, [props.idItem])

    const returnTypeViewer = (extension?: string, parameterViewerDwg?:ParameterViwer)  =>  {
        
        if(extension == "pdf" || extension == "docx" || extension == "doc" || extension == "xls" || 
        extension == "xlsx" || extension == "ppt" || extension == "pptx" || extension == "mpp" ||
        ( extension == "dwg" && parameterViewerDwg != null && parameterViewerDwg.converterType == 0)
        ){
            return ViewerOptions.Pdf
        } else if (extension == "png" || extension== "jpg" || extension== "gif" ||extension== "svg" ||
        extension == "bmp" || extension == "webp") {
            return ViewerOptions.Image
        }
        else if( extension == "dwg" && parameterViewerDwg != null && parameterViewerDwg.converterType == 1){
            return ViewerOptions.DwgImage
        }    
        else {
            return ViewerOptions.NoViewer
        }
    }

    function getHeaderWidth() 
    {
        if (!props.fullScreenSetted && props.isSidebarCollapsed)
            return "93%"

        return "100%"
    }

    function getWidthByViwer()
    {
        if (props.isSidebarCollapsed && !props.fullScreenSetted)
            return "89%"

        if (props.isSidebarCollapsed && props.fullScreenSetted)
            return "100%"

        if (!props.isSidebarCollapsed && !props.fullScreenSetted)
            return "68.5%"

        return "75.2%"
    }

    useEffect(() => {
        setStyleState(getWidthByViwer())
    }, [props.isSidebarCollapsed, props.fullScreenSetted])

    useEffect(() => {
        setStyleState(getWidthByViwer())
    }, [props.isSidebarCollapsed, props.fullScreenSetted])

    const openFieldDwg = () => {
        setIsFileConfigOpen(true)
    }

    const updateViewerPdw = (parameterViwer: ParameterViwer) => {
        setIsFileConfigOpen(false)
        setTypeViewer(returnTypeViewer(extension, parameterViwer))
        downloadViwer(props.idItem, false, parameterViwer);       
    }
    
    const activateSnackbar = (message: string) => {
        setSnackBarMessage(message);
        setSnackBarOpen(true);
    }

    const openPdfWindowPrint = (type: "pdf"|"image") => {
        printJS({
            printable: url,
            type: type,
            onIncompatibleBrowser() {
                activateSnackbar(t("YourBrowserDoesNotSupportThePrintingFunction.message"));
            },
            onError(error: any) {
                activateSnackbar(error.message);
            },
        })
    }


    return(
        <div style={{
            height: "100%"
        }} >
            <div
                style={{
                    height: "100%"
                }}
                className="fatherViwer">
                {loader ?
                    (
                        <div className={contentStyles.loader}>
                            <Loader size={120} className={loaderSave} />
                            <div className={contentStyles.Progressao}>
                                {(progress != 0 && progress != 100) && progress.toString() + "% "}
                            </div>
                        </div>
                    ) : (
                        isFileConfigOpen ? (
                            <FieldDwg
                                extension={extension!}
                                name={name!}
                                cancel={props.cancel!}
                                updateViewerPdw={updateViewerPdw}
                            />
                        ) : (
                            <div style={{
                                height: "100%",
                                background: "rgb(225, 225, 225)",
                                width: getHeaderWidth(),
                                position: "relative"
                            }}>
                                { typeViewer === ViewerOptions.ErrorViwer ? 
                                (
                                    <ErrorViwer
                                        id={props.idItem}
                                        name={name!}
                                        extension={extension!}
                                        downloadViwer={download}
                                        title={props.title}
                                        showList={props.showList}
                                        isAcept={props.isAcept}
                                        menuConfig={props.menuConfig}
                                        hideSidebar={hideSidebar}
                                        toggleHideSidebar={toggleHideSidebar}
                                        toggleHideDialog={props.toggleHideDialog!}
                                        cancel={props.cancel}
                                        changeModalContent={props.changeModalContent}
                                    />
                                ): url ? (
                                    <>
                                    
                                        {typeViewer === ViewerOptions.Pdf &&
                                        

                                        <ModalStyled
                                            fullScreenSetted={props.fullScreenSetted}
                                            isSidebarCollapsed={props.isSidebarCollapsed!}
                                            viewerOptions={ViewerOptions.Pdf}
                                            showViwerInfo={{ value: "0", isShown: false }}
                                            isAcept={props.isAcept}
                                            isModalViwer={isModalViwer}
                                            isSharedFilePage={props.isSharedFilePage}
                                        >
                                            <PDFPageViwer
                                                url={url}
                                                positionabsolute={props.positionAbsolute}
                                                menuConfig={props.menuConfig}
                                                showList={props.showList}
                                                title={props.title}
                                                isAcept={props.isAcept}
                                                hideSidebar={hideSidebar}
                                                heightViewer={previewWorkspaceViewerHeight}
                                                toggleHideSidebar={toggleHideSidebar}
                                                toggleHideDialog={props.toggleHideDialog!}
                                                cancel={props.cancel}
                                                changeModalContent={props.changeModalContent}
                                                isDetailPage={props.isDetailPage}
                                                openPdfWindowPrint={openPdfWindowPrint}    
                                                fullScreenSetted={props.fullScreenSetted} 
                                                isSidebarCollapsed={props.isSidebarCollapsed}
                                                activateSnackbar={activateSnackbar} 
                                                isFilePage={props.isFilePage}                   
                                            />
                                        </ModalStyled>
                                    }
                                    {typeViewer == ViewerOptions.Image &&
                                        <ModalStyled
                                            fullScreenSetted={props.fullScreenSetted}
                                            isSidebarCollapsed={props.isSidebarCollapsed!}
                                            viewerOptions={ViewerOptions.Image}
                                            showViwerInfo={{ value: "0", isShown: false }}
                                            isAcept={props.isAcept}
                                            isModalViwer={isModalViwer}                                        
                                            isSharedFilePage={props.isSharedFilePage}
                                        >
                                            <ImagePageViwer
                                                url={url}
                                                positionabsolute={props.positionAbsolute}
                                                menuConfig={props.menuConfig}
                                                showList={props.showList}
                                                toggleHideDialog={props.toggleHideDialog!}
                                                title={props.title}
                                                isAcept={props.isAcept}
                                                hideSidebar={hideSidebar}
                                                heightViewer={previewWorkspaceViewerHeight}
                                                toggleHideSidebar={toggleHideSidebar}
                                                cancel={props.cancel}
                                                changeModalContent={props.changeModalContent}
                                                isDetailPage={props.isDetailPage}
                                                openPdfWindowPrint={openPdfWindowPrint}    
                                                isFilePage={props.isFilePage}  
                                                fullScreenSetted={props.fullScreenSetted}
                                                isSidebarCollapsed={props.isSidebarCollapsed}      
                                            />
                                        </ModalStyled>
                                        }  
                                        {typeViewer == ViewerOptions.DwgImage && (
                                            <DwgViwerPng
                                                url={url}
                                                urlDWG={urlMultiFile}
                                                positionabsolute={props.positionAbsolute}
                                                menuConfig={props.menuConfig}
                                                showList={props.showList}
                                                toggleHideDialog={props.toggleHideDialog!}
                                                title={props.title}
                                                isAcept={props.isAcept}
                                                hideSidebar={hideSidebar}
                                                heightViewer={previewWorkspaceViewerHeight}
                                                toggleHideSidebar={toggleHideSidebar}
                                                cancel={props.cancel}
                                                changeModalContent={props.changeModalContent}
                                                isDetailPage={props.isDetailPage}
                                                openFileSetting={openFieldDwg}
                                                openPdfWindowPrint={openPdfWindowPrint}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                    {typeViewer === ViewerOptions.NoViewer &&
                                        <ModalStyled
                                            fullScreenSetted={props.fullScreenSetted}
                                            isSidebarCollapsed={props.isSidebarCollapsed!}
                                            viewerOptions={ViewerOptions.NoViewer}
                                            showViwerInfo={{ value: "0", isShown: false }}
                                            isAcept={props.isAcept}                                            
                                            isModalViwer={isModalViwer}                                            
                                            isSharedFilePage={props.isSharedFilePage}
                                        >
                                            <NoViwerPage
                                                id={props.idItem}
                                                name={name!}
                                                extension={extension!}
                                                downloadViwer={download}
                                                title={props.title}
                                                showList={props.showList}
                                                isAcept={props.isAcept}
                                                menuConfig={props.menuConfig}
                                                hideSidebar={hideSidebar}
                                                toggleHideSidebar={toggleHideSidebar}
                                                toggleHideDialog={props.toggleHideDialog!}
                                                cancel={props.cancel}
                                                changeModalContent={props.changeModalContent}
                                            />
                                        </ModalStyled>
                                        }
                                    </>
                                )}
                            </div>

                        )
                    )}

            </div>
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                message={snackBarMessage}
                onClose={() => setSnackBarOpen(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                sx={{ bottom: { xs: 50, sm: 20 } }}
            >
                <Alert elevation={6} variant="filled" sx={{ width: '100%' }} severity="error">
                    {snackBarMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ChoicerViwer;