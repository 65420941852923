import { Box, Typography } from '@mui/material';

interface IFormContextText {
    contextText?: string;
}

export default function FormContextText({
    contextText
}: IFormContextText) 
{
    return (
        <Box>
            <Typography
                sx={{
                    textAlign: "center",
                    font: "normal normal 600 18px/27px Segoe UI",
                    letterSpacing: "0px",
                }}
            >
                { contextText }
            </Typography>
        </Box>
    )
}