import React, { ChangeEvent, useEffect, useState, useRef, useCallback } from "react";
import {
    mergeStyleSets,
    Modal,
    IIconProps,
    IContextualMenuProps
  } from '@fluentui/react';
  import { GetRevisionTracker } from '../../../services/Item/ItemServicesGet';
  import { WorkSpaceItemTrakker } from '../../../model/ItemModel';
  import WorkSpaceItemListFileTrackerRevision from './WorkSpaceItemListFileTrackerRevision';
  import { useTranslation } from "react-i18next";
import { minWidth } from "@mui/system";
import WorkSpaceItemListFileComponentImage from "../WorkSpaceItemListFileComponentImage";
import { AiOutlineCloseCircle} from "react-icons/ai";
import LoaderComp from '../../../components/Loader/LoaderComp';

interface WorkSpaceItemListFileTrackerProps {
    idItem?:string;
    isModalOpen:boolean,
    showModal: () => void;
    hideModal: () => void;
}


const WorkSpaceItemListFileTracker: React.FC<WorkSpaceItemListFileTrackerProps> = ({
  idItem, isModalOpen, showModal, hideModal
}) => {

  const [workRevisionTracker, setWorkRevisionTracker] = useState<WorkSpaceItemTrakker|undefined>(undefined);
  const { t, i18n } = useTranslation();
  const [wait, setWait] = useState<boolean>(false);

  useEffect(() => {
    if(isModalOpen && idItem != undefined)
    {
      setWorkRevisionTracker(undefined);
      setWait(true);
      GetRevisionTracker(idItem)
      .then((itens) => {
        setWorkRevisionTracker(itens);
      })
      .catch((error) => {
        
      })
      .finally(()=>{
        setWait(false);
      });
    }
    else
    {
      setWorkRevisionTracker(undefined);
    }
  }, [isModalOpen, idItem, i18n.language]);

    return (
      <Modal
        titleAriaId="Tracker"
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
      >
        <div style={{
          margin:"16px"
        }}>
        <div
          style={{
              display:"inline-flex",
              width:"100%",
              marginBottom:"5px"
          }}
        >
          <h3 style={{margin:"0px"}}>{t("Revisao.tracker")}</h3>
            <AiOutlineCloseCircle
              style={{
                fontSize:"26px",
                marginLeft:"auto",
                cursor:"pointer"
              }}
              onClick={hideModal}
            /> 
        </div>

        {wait && (
          <LoaderComp big={true}/>
        )}

        {wait == false && workRevisionTracker != undefined && (
        <div style={{
            width:"100%",
            maxWidth:"850px"
        }}>
          <div style={{
            display:"inline-flex",
            width:"100%"
          }}>
            <div>
                  <WorkSpaceItemListFileComponentImage extension={workRevisionTracker.itemExtension} isFolder={false}/>
              </div>
              <div style={{
                  paddingLeft:"6px",
                  paddingRight:"6px"
              }}>
                  {(workRevisionTracker.itemExtension != undefined && workRevisionTracker.itemExtension != "")?
                      workRevisionTracker.itemName + "." + workRevisionTracker.itemExtension:workRevisionTracker.itemName}
              </div>    
          </div>
            <div style={{
              width:"100%"
            }}>
              {(workRevisionTracker.trackerList != undefined && workRevisionTracker.trackerList.length > 0) ?(
                <ul style={{
                  paddingInlineStart:"0px"
                }}>
                   <li
                        style={{
                            display:"inline-flex",
                            width:"100%",
                            fontWeight:"bold",
                            borderLeft:" 1px solid ",
                            borderRight: "1px solid",
                            borderBottom: "1px solid",
                            borderTop: "1px solid"
                        }}
                    >
                      <div style={{width: "9%"}}>
                        {t("Revisao.term")}
                      </div>
                        <div
                            style={{
                                width:"65%"
                            }}
                        >
                            {t("Revisao.message")}
                        </div>
                        <div
                            style={{
                                width:"10%",
                                textAlign:"center"
                            }}
                        >
                            {t("Revisao.name")}
                        </div>
                        <div
                            style={{
                                width:"10%",
                                textAlign:"center"
                            }}
                        >
                            Ip
                        </div>
                        <div
                            style={{
                                width:"15%",
                                textAlign:"center"
                            }}
                        >
                            {t("Revisao.time")}
                        </div>
                    </li>
                  {workRevisionTracker.trackerList.map((workRevTracker) => (
                    <WorkSpaceItemListFileTrackerRevision
                      key={workRevTracker.id} 
                      workSpaceItemRevisionTrakker={workRevTracker}
                    />
                  ))}
                </ul>
              ):(
                t("Revisao.noEntries")
              )}
            </div>
          </div>
          )}
        </div>
      </Modal>
    );
}

export default WorkSpaceItemListFileTracker;