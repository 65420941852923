import React, { useEffect, useState } from "react";
import { Link, Tooltip, Typography } from "@mui/material";
import { UseStatus, UseStatusWithCurrentOperation } from "./hooks/useStatus";
import { ItemValidationRule } from "../../model/ItemModel";
import LoaderComp from "../Loader/LoaderComp";

const statusStyle = {
    color: "black",
    background: "rgb(45, 45, 45, 0.05) 0% 0% no-repeat padding-box",
    borderRadius: "100px",
    padding: '5px 6px 5px 6px',
    width: '74px',
    height: '32px',
    '&:hover': {
        cursor: 'pointer'
    }
}

interface IStatus {
    onClick?: () => void;
    release: boolean;
    validationObject: ItemValidationRule | undefined;
    type: string;
    batchWaiter?: boolean;
    archiving?:boolean;
    expired?:boolean;
    draft?: boolean
}

const Status: React.ForwardRefExoticComponent<IStatus> = React.forwardRef(({
    onClick,
    release,
    type,
    validationObject,
    batchWaiter,
    archiving,
    expired,
    draft
}, ref) => {

    const currentStatus = UseStatus(release,validationObject, type, batchWaiter,archiving, expired);

    const currentOperation = UseStatusWithCurrentOperation(release, validationObject, type, batchWaiter,archiving, expired).currentOperation;

    function tooltipTitleRender(): string {
        return currentOperation !== undefined ? `Flow - ${currentStatus}` : currentStatus
    }

    return (
        <Link
            onClick={onClick}
            underline="none"
            sx={statusStyle}
        >
            <Tooltip title={tooltipTitleRender()}>
                <span>
                    { currentStatus }
                </span>
            </Tooltip>
        </Link>
    )
});

export default Status;