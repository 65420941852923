export interface PptxConfig {}

export const Pptx: React.FC<PptxConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .pptx-1 {
              fill: #fff;
            }

            .pptx-2 {
              fill: none;
              isolation: isolate;
            }

            .pptx-3 {
              fill: #bc3618;
            }

            .pptx-3, .pptx-4 {
              fill-rule: evenodd;
            }

            .pptx-4 {
              fill: #ff8f6b;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_746" data-name="Grupo 746">
        <g id="Grupo_731" data-name="Grupo 731">
          <path id="Caminho_136" data-name="Caminho 136" className="pptx-3" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_138" data-name="Caminho 138" className="pptx-4" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="pptx" className="pptx-2">
          <g className="pptx-2">
            <path className="pptx-1" d="m7.65,33.94h-.03v4.25h-1.59v-10.22h1.59v1.23h.03c.54-.93,1.34-1.39,2.38-1.39.89,0,1.58.31,2.08.94s.75,1.46.75,2.52c0,1.17-.28,2.1-.84,2.8s-1.33,1.05-2.3,1.05c-.89,0-1.58-.39-2.06-1.18Zm-.04-2.76v.87c0,.51.16.94.49,1.3s.74.53,1.24.53c.59,0,1.05-.23,1.38-.69.33-.46.5-1.1.5-1.92,0-.69-.16-1.23-.47-1.62-.31-.39-.73-.58-1.27-.58-.56,0-1.02.2-1.36.6s-.52.9-.52,1.5Z"/>
            <path className="pptx-1" d="m16.08,33.94h-.03v4.25h-1.59v-10.22h1.59v1.23h.03c.54-.93,1.34-1.39,2.38-1.39.89,0,1.58.31,2.08.94.5.62.75,1.46.75,2.52,0,1.17-.28,2.1-.84,2.8s-1.33,1.05-2.3,1.05c-.89,0-1.58-.39-2.06-1.18Zm-.04-2.76v.87c0,.51.16.94.49,1.3s.74.53,1.24.53c.59,0,1.05-.23,1.38-.69.33-.46.5-1.1.5-1.92,0-.69-.16-1.23-.47-1.62-.31-.39-.73-.58-1.27-.58-.56,0-1.02.2-1.36.6s-.52.9-.52,1.5Z"/>
            <path className="pptx-1" d="m26.59,34.89c-.31.16-.72.23-1.22.23-1.36,0-2.04-.65-2.04-1.96v-3.96h-1.17v-1.24h1.17v-1.62l1.59-.45v2.07h1.67v1.24h-1.67v3.5c0,.42.08.71.23.89s.4.27.75.27c.27,0,.5-.08.7-.23v1.26Z"/>
            <path className="pptx-1" d="m34.01,27.97l-2.33,3.54,2.3,3.46h-1.84l-1.23-2.17c-.08-.13-.17-.3-.27-.51h-.03s-.11.21-.29.51l-1.25,2.17h-1.83l2.38-3.43-2.26-3.57h1.85l1.21,2.28c.09.17.18.34.27.52h.02l1.57-2.8h1.74Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
