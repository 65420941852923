import React, { useState, useEffect } from "react";
import TextFieldComp from "../../../components/TextField/TextFieldComp";
import { useTranslation } from "react-i18next";
import { SetDescription } from "../../../services/Item/ItemServicesCRUD";
import Message from "../../../components/Message/Message";
import { MessageBarType } from "@fluentui/react";
import Btn from "../../../components/Button/Btn";
import LoaderComp from "../../../components/Loader/LoaderComp";

export interface ItemDescription {
	idMongo?: string | undefined;
	description?: string;
}

export interface DescriptionUpdateParams {
	itemDescription: ItemDescription;
	saveOkMetod: () => void;
}

const DescriptionUpdate = (props: DescriptionUpdateParams) => {
	const { t, i18n } = useTranslation();
	const [descriptionChange, setDescriptionChange] = useState<undefined | string>(
		props.itemDescription?.description,
	);
	const [messageErrorString, setMessageErrorString] = useState<string>(t("ErroAoSalvar.message"));
	const [isMessageError, setMessageError] = useState<boolean>(false);
	const [wait, setWait] = useState<boolean>(false);

	const onChangeDescription = React.useCallback(
		(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
			if (newValue != undefined) {
				setDescriptionChange(newValue);
			} else {
				setDescriptionChange("");
			}
		},
		[],
	);

	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			descriptionUpdate();
		}
	};

	const descriptionUpdate = () => {
		setWait(true);
		if (props.itemDescription && props.itemDescription.idMongo) {
			SetDescription({
				itemDescription: descriptionChange!,
				itemId: props.itemDescription.idMongo,
			})
				.then((description) => {
					props.saveOkMetod();
				})
				.catch((error) => {
					if (error.response && error.response.data && typeof error.response.data === "string") {
						setMessageErrorString(error.response.data);
					} else {
						setMessageErrorString(t("ErroAoSalvar.message"));
					}
					setMessageError(true);
				})
				.finally(() => {
					setWait(false);
				});
		}
	};

	return (
		<div>
			<TextFieldComp
				label={t("Description.message")}
				value={descriptionChange}
				onChange={onChangeDescription}
				autofocus
				onKeyDown={onKeyDown}
			/>
			{isMessageError && <Message text={messageErrorString} type={MessageBarType.error} />}
			<Btn text={t("Salvar.message")} onClick={descriptionUpdate} disabled={wait} />
			{wait && <LoaderComp />}
		</div>
	);
};

export default DescriptionUpdate;
