import TextFieldComp from "../../../components/TextField/TextFieldComp"
import { useTranslation } from "react-i18next"
import { FormEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react'
import Btn from "../../../components/Button/Btn"
import LoaderComp from "../../../components/Loader/LoaderComp"
import Message from "../../../components/Message/Message"
import { MessageBarType } from "@fluentui/react"
import { GetOneItem } from "../../../services/Item/ItemServicesGet"
import { WorkSpaceUserName } from "../../../model/ItemModel"
import { UpdateFisicPosition } from "../../../services/Item/ItemServicesCRUD"


export interface FisicStorage {
    permissionType?:number,
    local?: string,
    shelf?: string,
    bookShelf?: string,
    position?: string,
    idMongo?: string
}

export interface FisicStorageConfig {
    fisicPropsStorage: FisicStorage
    saveOkmethod: () => void
}

const StorageInformation = (props: FisicStorageConfig) => {

    const { t, i18n } = useTranslation()

    
    const [shelf, setShelf] = useState<undefined|string>(props.fisicPropsStorage?.shelf);
    const [local, setLocal] = useState<undefined|string>(props.fisicPropsStorage?.local);

    const [bookshelf, setBookShelf] = useState<undefined|string>(props.fisicPropsStorage?.bookShelf);
    const [fisicPosition, setFisicPosition] = useState<undefined|string>(props.fisicPropsStorage?.position);
    const [wait, setWait] = useState<boolean>(false);
    const [isMessageError, setIsMessageError] = useState<boolean>(false);
    const [disabele, setDisabele] = useState<boolean>(true);
    const [messageErrorString, setMessageErrorString] = useState<string>(t("ErroAoSalvar.message"));

    
    useEffect(() => {
        if(props.fisicPropsStorage.permissionType == 1 || props.fisicPropsStorage.permissionType == 3)
        {
            setDisabele(false);
        }

        setWait(true);
        GetOneItem(props.fisicPropsStorage.idMongo!)
        .then(data => {
            if (data !== undefined) {
                setLocal(data.local);
                setShelf(data.shelf);
                setBookShelf(data.bookShelf);
                setFisicPosition(data.position);
            }
        })
        .catch((error) =>{
            if( error.response && error.response.data && typeof error.response.data  === 'string'){
                setMessageErrorString(error.response.data);
            }
            else
            {
                setMessageErrorString(t("NotFound.message"));
            }
            setIsMessageError(true);
        })
        .finally(()=>{
            setWait(false);
        });
    }, [])

    const StorageUpdate = () => {
        setWait(true);
        if(props.fisicPropsStorage && props.fisicPropsStorage.idMongo)
        {
            UpdateFisicPosition({ bookShelf: bookshelf, local: local, shelf: shelf, position: fisicPosition, ItemId: props.fisicPropsStorage.idMongo })
            .then((data) => {
                props.saveOkmethod();
            })
            .catch((error) =>{
                if( error.response && error.response.data && typeof error.response.data  === 'string'){
                    setMessageErrorString(error.response.data);
                }
                else
                {
                    setMessageErrorString(t("ErroAoSalvar.message"));
                }
                setIsMessageError(true);
            })
            .finally(()=>{
                setWait(false);
            });
        }
        
    }

    const StorageDelete = () => {
        setWait(true);
        if(props.fisicPropsStorage && props.fisicPropsStorage.idMongo)
        {
            UpdateFisicPosition({ bookShelf: "", local: "", shelf: "", position: "", ItemId: props.fisicPropsStorage.idMongo })
            .then((data) => {
                props.saveOkmethod();
            })
            .catch((error) =>{
                if( error.response && error.response.data && typeof error.response.data  === 'string'){
                    setMessageErrorString(error.response.data);
                }
                else
                {
                    setMessageErrorString(t("ErroAoSalvar.message"));
                }
                setIsMessageError(true);
            })
            .finally(()=>{
                setWait(false);
            });
        }
        
    }

    const onkeyDown = (event: KeyboardEvent<HTMLInputElement>) => {

        if (event.key === "Enter") {
            StorageUpdate()
        }

    }

    const onChangeSetLocal = useCallback(
        (
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          if (newValue != undefined) {
            setLocal(newValue);
          }
          else
          {
            setLocal("");
          }
        },
        []
    );

    const onChangeSetShelf = useCallback(
        (
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          if (newValue != undefined) {
            setShelf(newValue);
          }
          else
          {
            setShelf("");
          }
        },
        []
    );

    const onChnageSetBookShelf = useCallback(
        (
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          if (newValue != undefined) {
            setBookShelf(newValue);
          }
          else
          {
            setBookShelf("");
          }
        },
        []
    );

    const onChangeSetPosition = useCallback(
        (
          event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
          newValue?: string
        ) => {
          if (newValue != undefined) {
            setFisicPosition(newValue);
          }
          else
          {
            setFisicPosition("");
          }
        },
        []
    );

    return (
        <div>
            <TextFieldComp
                label={"Local"}
                value={local}
                onChange={onChangeSetLocal}
                onKeyDown={onkeyDown}
                autofocus
                disabled={wait || disabele}
            />
            
            <TextFieldComp
                label={t("StroageInformation.bookcase")}
                value={shelf}
                onChange={onChangeSetShelf}
                onKeyDown={onkeyDown}
                autofocus
                disabled={wait || disabele}
            />

            <TextFieldComp
                label={t("StroageInformation.shelf")}
                value={bookshelf}
                onChange={onChnageSetBookShelf}
                onKeyDown={onkeyDown}
                disabled={wait || disabele}
            />    

            <TextFieldComp
                label={t("StroageInformation.position")}
                value={fisicPosition}
                onChange={onChangeSetPosition}
                onKeyDown={onkeyDown}
                disabled={wait || disabele}
            />     

            {isMessageError && (
                <Message text={messageErrorString} type={MessageBarType.error} />
            )}

            <br/>

            <Btn text={t("Salvar.message")} onClick={StorageUpdate} disabled={wait || disabele} />
   
            <Btn text={t("Deletar.message")} onClick={StorageDelete} disabled={wait || disabele} />
            {wait && (
                    <LoaderComp/>
            )}

        </div>
    )
}

export default StorageInformation