export interface PdfConfig {}

export const Pdf: React.FC<PdfConfig> = ({}) => {
  return(
    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.76 51.94">
      <defs>
        <style>
          {`
            .pdf-1 {
              fill: #fff;
            }

            .pdf-2 {
              fill: #fb8d8d;
            }

            .pdf-2, .pdf-3 {
              fill-rule: evenodd;
            }

            .pdf-4 {
              fill: none;
              isolation: isolate;
            }

            .pdf-3 {
              fill: #cb0606;
            }
          `}
        </style>
      </defs>
      <g id="Grupo_742" data-name="Grupo 742">
        <g id="Grupo_727" data-name="Grupo 727">
          <path id="Caminho_64" data-name="Caminho 64" className="pdf-3" d="m39.76,13.67v34.17c0,2.27-1.85,4.1-4.11,4.1H4.11C1.85,51.94,0,50.1,0,47.84h0V4.1C0,1.83,1.85,0,4.11,0h0s21.94,0,21.94,0l13.71,13.67Z"/>
          <path id="Caminho_65" data-name="Caminho 65" className="pdf-2" d="m39.76,12.9v1.7h-11.21c-2.32,0-3.39-1.88-3.39-4.2V0h1.7l12.9,12.9Z"/>
        </g>
        <g id="pdf" className="pdf-4">
          <g className="pdf-4">
            <path className="pdf-1" d="m11.65,33.94h-.03v4.25h-1.59v-10.22h1.59v1.23h.03c.54-.93,1.34-1.39,2.38-1.39.89,0,1.58.31,2.08.94s.75,1.46.75,2.52c0,1.17-.28,2.1-.84,2.8s-1.33,1.05-2.3,1.05c-.89,0-1.58-.39-2.06-1.18Zm-.04-2.76v.87c0,.51.16.94.49,1.3s.74.53,1.24.53c.59,0,1.05-.23,1.38-.69.33-.46.5-1.1.5-1.92,0-.69-.16-1.23-.47-1.62-.31-.39-.73-.58-1.27-.58-.56,0-1.02.2-1.36.6s-.52.9-.52,1.5Z"/>
            <path className="pdf-1" d="m24.83,34.97h-1.59v-1.19h-.03c-.51.9-1.3,1.35-2.36,1.35-.86,0-1.55-.31-2.07-.94-.52-.63-.78-1.48-.78-2.56,0-1.16.29-2.08.86-2.78s1.33-1.05,2.29-1.05,1.63.38,2.06,1.13h.03v-4.33h1.59v10.36Zm-1.57-3.2v-.92c0-.5-.16-.92-.49-1.26s-.74-.52-1.24-.52c-.59,0-1.06.22-1.4.66s-.51,1.05-.51,1.84c0,.71.16,1.27.49,1.68.33.41.76.62,1.32.62s.98-.2,1.32-.6c.34-.4.51-.9.51-1.51Z"/>
            <path className="pdf-1" d="m30.86,25.9c-.21-.12-.46-.18-.73-.18-.77,0-1.16.44-1.16,1.31v.95h1.63v1.24h-1.62v5.76h-1.59v-5.76h-1.2v-1.24h1.2v-1.13c0-.74.24-1.32.72-1.75.48-.43,1.09-.64,1.81-.64.39,0,.7.04.93.13v1.32Z"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
