import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';
import DocumentDetailTabWrapper from './components/DocumentDetailTabWrapper/DocumentDetailTabWrapper';
import DocumentDetailTitleTabs from './components/DocumentDetailTitleTabs/DocumentDetailTitleTabs';

interface IDocumentDetailPivot
{
    children: React.ReactNode;
    isLoading: boolean;
}

export default function DocumentDetailPivot({
    children,
    isLoading
}: IDocumentDetailPivot)
{
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    const { t } = useTranslation();

    return (
        <DocumentDetailTabWrapper
            value={value}
        >
            <DocumentDetailTitleTabs 
                handleTabChange={handleChange}
                isLoading={isLoading}
            />
            { children }
        </DocumentDetailTabWrapper>
    )
}

