export interface PstConfig {}

export const Pst: React.FC<PstConfig> = ({}) => {
  return(
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 39.8 51.9" style={{ background: "new 0 0 39.8 51.9" }} xmlSpace="preserve">
	<style type="text/css">
		{`
			.pst0{fill-rule:evenodd;clip-rule:evenodd;fill:#0072C6;}
			.pst1{fill-rule:evenodd;clip-rule:evenodd;fill:#8FB2F9;}
			.pst2{fill:#FFFFFF;}
		`}
	</style>
	<g id="Grupo_727">
		<path id="Caminho_64" className="pst0" d="M39.8,13.7v34.2c0,2.3-1.8,4.1-4.1,4.1H4.1c-2.3,0-4.1-1.8-4.1-4.1l0,0V4.1C0,1.8,1.9,0,4.1,0
			l0,0h21.9C26.1,0,39.8,13.7,39.8,13.7z"/>
		<path id="Caminho_65" className="pst1" d="M39.8,12.9v1.7H28.5c-2.3,0-3.4-1.9-3.4-4.2V0h1.7C26.9,0,39.8,12.9,39.8,12.9z"/>
	</g>
	<g>
		<path className="pst2" d="M11.3,35L11.3,35l0,4.6H9.6V28.6h1.7v1.3h0c0.6-1,1.4-1.5,2.5-1.5c1,0,1.7,0.3,2.2,1s0.8,1.6,0.8,2.7
			c0,1.2-0.3,2.3-0.9,3c-0.6,0.8-1.4,1.1-2.5,1.1C12.6,36.3,11.9,35.9,11.3,35z M11.3,32.1V33c0,0.5,0.2,1,0.5,1.4
			c0.3,0.4,0.8,0.6,1.3,0.6c0.6,0,1.1-0.2,1.5-0.7c0.4-0.5,0.5-1.2,0.5-2.1c0-0.7-0.2-1.3-0.5-1.7c-0.3-0.4-0.8-0.6-1.4-0.6
			c-0.6,0-1.1,0.2-1.5,0.6C11.5,30.9,11.3,31.4,11.3,32.1z"/>
		<path className="pst2" d="M18.2,35.9v-1.6c0.6,0.5,1.3,0.7,2.1,0.7c1,0,1.5-0.3,1.5-0.9c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.3
			c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3-0.1-0.6-0.3-0.8-0.4s-0.5-0.3-0.6-0.5c-0.2-0.2-0.3-0.4-0.4-0.6
			s-0.1-0.5-0.1-0.8c0-0.4,0.1-0.7,0.3-1c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.3,1-0.4c0.4-0.1,0.7-0.1,1.1-0.1
			c0.7,0,1.3,0.1,1.9,0.3v1.5c-0.5-0.4-1.1-0.5-1.8-0.5c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.3,0.1-0.4,0.2s-0.2,0.2-0.3,0.3
			c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.3,0.1,0.4s0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.6,0.2
			c0.3,0.1,0.6,0.3,0.9,0.4c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5,0.1,0.8c0,0.4-0.1,0.7-0.3,1
			s-0.4,0.5-0.7,0.7s-0.6,0.3-1,0.4c-0.4,0.1-0.8,0.1-1.2,0.1C19.5,36.3,18.8,36.2,18.2,35.9z"/>
		<path className="pst2" d="M29.1,36c-0.3,0.2-0.8,0.2-1.3,0.2c-1.5,0-2.2-0.7-2.2-2.1V30h-1.3v-1.3h1.3v-1.7l1.7-0.5v2.2h1.8V30h-1.8v3.8
			c0,0.4,0.1,0.8,0.2,1s0.4,0.3,0.8,0.3c0.3,0,0.5-0.1,0.7-0.2V36z"/>
	</g>
	</svg>
  )
}

